<template>
  <v-row no-gutters :style="`padding-top: ${paddingTop}px`">
    <v-col cols="12">
      <QuestionItem
        v-for="(item, index) in questionAnswers"
        :key="index"
        :text="item.name"
        :isUserSelected="isReopenedQuestion && isSelected(item)"
        :class="[
          { 'blocked-all': timeIsOut },
          !isReopenedQuestion && modalType === 'challenge' && isSelected(item)
            ? 'selected-answer'
            : isReopenedQuestion
            ? isCorrect(item)
              ? 'correct-answer'
              : ''
            : isSelected(item)
            ? isCorrect(item)
              ? 'correct-answer'
              : 'incorrect-answer'
            : '',
        ]"
        @selectAnswer="selectAnswer(item)"
      />
    </v-col>

    <template v-if="isReopenedQuestion">
      <v-col cols="12" class="d-flex flex-row align-center mb-3">
        <mdicon class="ak-icon mr-2" name="AccountCircle" size="26" />
        <p class="ak-hint ak-colorText-sandWhite ma-0">Ihre Antworten</p>
      </v-col>
      <v-col cols="12" class="d-flex flex-row align-center">
        <span class="ak-correct-answer-hint mr-2"></span>
        <p class="ak-hint ak-colorText-sandWhite ma-0">Korrekte Lösung</p>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import QuestionItem from '@/components/modules/games/common/QuestionItem';

export default {
  name: 'TextQuestion',
  components: { QuestionItem },
  props: {
    questionAnswersTaked: {
      type: Array,
      default: () => [],
    },
    questionAnswers: {
      type: Array,
      default: () => [],
    },
    questionAnswersLinks: {
      type: Array,
      default: () => [],
    },
    questionAnswersCountRequired: {
      type: Number,
      default: null,
    },
    questionPostLink: {
      type: String,
      default: null,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
    passedQuestions: {
      type: Number,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
    timeIsOut: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      correct: require('@/assets/images/correct.svg'),
      activeIndex: [],
      takedAnswer: null,
      selectedAnswers: [],
      selectedAnswersId: [],
      paddingTop: 0,
      correctAnswer: [],
    };
  },

  watch: {
    passedQuestions: {
      immediate: true,
      handler(oldVal, newVal) {
        if (oldVal < newVal) {
          this.$emit('validGoNextButton', oldVal < newVal);
        } else {
          this.$emit('validGoNextButton', this.allDone && !(oldVal < newVal));
        }
      },
    },

    allDone(val) {
      this.$emit('validGoNextButton', val);
    },
  },

  computed: {
    allDone() {
      if (this.modalType === 'challenge') {
        return this.activeIndex.length > 0;
      } else {
        return this.correctAnswer.length === this.questionAnswersCountRequired;
      }
    },
  },

  created() {
    this.questionAnswersTaked.forEach((item) => {
      this.activeIndex.push(item.choice);
    });
  },

  methods: {
    isCorrect(item) {
      if (this.isReopenedQuestion) {
        if ('correct' in item) {
          return item.correct;
        } else {
          return false;
        }
      } else {
        return item.isCorrect;
      }
    },

    isSelected(item) {
      if (this.isReopenedQuestion) {
        if ('given' in item) {
          return item.given;
        } else {
          return false;
        }
      } else {
        return Boolean(this.activeIndex.includes(item.id));
      }
    },

    removeId(val, array) {
      var new_keys = array.filter((item) => item !== val);
      return new_keys;
    },

    async selectAnswer(item) {
      if (this.modalType === 'challenge') {
        this.takedAnswer = item;
        let tmpIndex = item.id;

        const answer = {
          choice: item.id,
          type: 'SINGLE',
        };

        if (this.selectedAnswers.includes(item.id)) {
          this.questionAnswers.forEach((el) => {
            if (el.id === item.id) {
              el.isSelected = false;
            }
          });

          this.activeIndex = this.activeIndex.filter(
            (item) => item !== tmpIndex
          );

          this.selectedAnswers = this.removeId(item.id, this.selectedAnswers);

          let deleteLink = null;

          deleteLink = this.questionAnswersLinks.filter(
            (el) =>
              el.method == 'DELETE' &&
              el.rel == 'answer' &&
              el.answer.choice === item.id
          );

          let tmpArray = this.selectedAnswersId.filter(
            (el) => el.choice === item.id
          );

          if (deleteLink.length > 0) {
            await this.$axios
              .delete(deleteLink[0].href)
              .then((res) => {
                if (res.status == 204) {
                  console.log('answer was delete');
                }
              })
              .catch((err) => {
                console.log('answer not delete: ', err);
              });
          } else {
            await this.$axios
              .delete(`${this.questionPostLink}${tmpArray[0].id}`)
              .then((res) => {
                if (res.status == 204) {
                  console.log('answer was delete');
                }
              })
              .catch((err) => {
                console.log('answer not delete: ', err);
              });
          }
        } else {
          if (!this.activeIndex.includes(item.id)) {
            this.activeIndex.push(item.id);
          }

          this.selectedAnswers.push(item.id);

          await this.$axios
            .post(this.questionPostLink, answer, this.axiosPostPutConfig)
            .then((res) => {
              this.selectedAnswersId.push(res.data.data);
              this.questionAnswers.forEach((el) => {
                if (el.id === item.id) {
                  el.isSelected = true;
                }
              });
              console.log('answer was setted');
            });
        }
        this.takedAnswer = null;
      } else if (this.isReopenedQuestion) {
        return true;
      } else {
        this.takedAnswer = item;
        let tmpIndex = item.id;

        const answer = {
          choice: item.id,
          type: 'SINGLE',
        };

        await this.$axios
          .post(this.questionPostLink, answer, this.axiosPostPutConfig)
          .then((res) => {
            this.questionAnswers.forEach((el) => {
              if (el.id === item.id) {
                el.isCorrect = res.data.data.correct;
                el.isSelected = true;

                if (
                  res.data.data.correct &&
                  !this.correctAnswer.includes(res.data.data.choice)
                ) {
                  this.correctAnswer.push(res.data.data.choice);
                }
              }
            });
          });

        this.$forceUpdate();

        if (!this.selectedAnswers.includes(item.id)) {
          if (!this.activeIndex.includes(item.id)) {
            this.activeIndex.push(item.id);
          }

          this.selectedAnswers.push(item.id);

          if (!item.isCorrect) {
            setTimeout(() => {
              this.activeIndex = this.activeIndex.filter(
                (item) => item !== tmpIndex
              );

              this.selectedAnswers = this.removeId(
                item.id,
                this.selectedAnswers
              );
            }, 700);
          }
        } else {
          this.questionAnswers.forEach((el) => {
            if (el.id === item.id) {
              el.isSelected = false;
            }
          });

          this.activeIndex = this.activeIndex.filter(
            (item) => item !== tmpIndex
          );

          this.selectedAnswers = this.removeId(item.id, this.selectedAnswers);
        }

        this.takedAnswer = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question {
  background-color: var(--v-primary-base);
  position: relative;
}

::v-deep .ak-question-answer {
  &.correct-answer {
    background-color: var(--v-success-base);
    color: #fff;
  }

  &.incorrect-answer {
    background-color: var(--v-error-base);
    color: #fff;
  }

  &.disabled-answer:not(.incorrect-answer):not(.correct-answer) {
    background-color: var(--v-primary-lighten5);
  }

  &.selected-answer {
    background-color: var(--v-accent-base);
  }
}

.blocked-all {
  pointer-events: none;
}

.ak-correct-answer-hint {
  background: var(--v-success-base);
  height: 20px;
  width: 50px;
  border-radius: 8px;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
}
</style>
