var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"80vw","max-width":"700","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.dialogIsOpen),callback:function ($$v) {_vm.dialogIsOpen=$$v},expression:"dialogIsOpen"}},[(_vm.dialogSummaryIsOpen)?[_c('ChallengesSummary',{attrs:{"title":_vm.levelTitle,"unitTitle":_vm.unitTitle,"summaryInfo":_vm.summaryInfo,"summaryInfoLinks":_vm.summaryInfoLinks},on:{"closeModal":_vm.closeModal,"closeChallenge":_vm.closeModal,"updateList":_vm.updateList,"repeatChallenge":_vm.repeatChallenge,"reOpenQuestion":_vm.getOpenQuestion}})]:_vm._l((_vm.questionList),function(item,index){return _c('v-card',{key:index,staticClass:"ak-question pa-0",class:{
        'current-question': index === _vm.passedQuestions - 1,
      },attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-container',{staticClass:"pt-4 pt-md-6 px-4"},[_c('ModalTitleChallenge',{attrs:{"isReopenedQuestion":_vm.isReopenedQuestion,"isCurrentQuestion":index === _vm.passedQuestions - 1,"isStartMemoryTimer":_vm.isStartMemoryTimer,"title":_vm.isReopenedQuestion ? item.data.name : item.name,"description":_vm.isReopenedQuestion ? item.data.description : null,"levelTitle":_vm.levelTitle,"unitTitle":_vm.unitTitle,"isMemory":(_vm.isReopenedQuestion ? item.data.type : item.type) === 'MEMORY',"timeAvailable":_vm.timeAvailable,"timeSpent":_vm.timeSpent,"timingMemory":(_vm.isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
                ? item.timeAvailable
                : 0,"timingMemorySpent":(_vm.isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
                ? item.timeSpent
                : 0},on:{"closeModal":_vm.closeModal,"memoryTimeOut":_vm.memoryTimeOut,"timeOut":_vm.timeOut}})],1),_c('v-container',[(
              (_vm.isReopenedQuestion ? item.data.type : item.type) ===
              'MULTI_CHOICE'
            )?[_c('TextQuestion',{attrs:{"questionAnswers":_vm.isReopenedQuestion
                  ? item.data.answerSummaries
                  : item.answerOptions,"questionAnswersTaked":item.answers,"questionPostLink":_vm._f("getPostLink")(item.links),"passedQuestions":_vm.passedQuestions,"isReopenedQuestion":_vm.isReopenedQuestion,"modalType":_vm.modalType,"timeIsOut":_vm.timeIsOut},on:{"closeModal":_vm.closeModal,"clearGame":_vm.clearGame,"validGoNextButton":_vm.validGoNextButton}})]:_vm._e(),(
              (_vm.isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
            )?[_c('MemoryQuestion',{attrs:{"questionAnswersTaked":item.answers,"questionAnswers":_vm.isReopenedQuestion
                  ? item.data.answerSummaries
                  : item.answerOptions,"questionPostLink":_vm._f("getPostLink")(item.links),"passedQuestions":_vm.passedQuestions,"isReopenedQuestion":_vm.isReopenedQuestion,"modalType":_vm.modalType,"memoryTimeIsOut":item.timeIsOf || _vm.memoryTimeIsOut,"timeIsOut":_vm.timeIsOut},on:{"closeModal":_vm.closeModal,"clearGame":_vm.clearGame,"validGoNextButton":_vm.validGoNextButton,"startMemoryTimer":_vm.startMemoryTimer,"memoryTimeOut":_vm.memoryTimeOut}})]:_vm._e(),(
              (_vm.isReopenedQuestion ? item.data.type : item.type) === 'IMAGE'
            )?[_c('PictureQuestion',{attrs:{"questionAnswersTaked":item.answers,"questionPicture":_vm.isReopenedQuestion ? item.data.imageSrc : item.imageSrc,"questionAnswers":_vm.isReopenedQuestion
                  ? item.data.answerSummaries
                  : item.answerOptions,"questionPostLink":_vm._f("getPostLink")(item.links),"passedQuestions":_vm.passedQuestions,"isReopenedQuestion":_vm.isReopenedQuestion,"modalType":_vm.modalType,"timeIsOut":_vm.timeIsOut},on:{"closeModal":_vm.closeModal,"clearGame":_vm.clearGame,"validGoNextButton":_vm.validGoNextButton}})]:_vm._e(),(
              (_vm.isReopenedQuestion ? item.data.type : item.type) === 'PUZZLE'
            )?[_c('PuzzleQuestion',{attrs:{"questionAnswersTaked":item.answers,"questionAnswers":_vm.isReopenedQuestion
                  ? item.data.answerSummaries
                  : item.answerOptions,"questionPostLink":_vm._f("getPostLink")(item.links),"passedQuestions":_vm.passedQuestions,"isReopenedQuestion":_vm.isReopenedQuestion,"modalType":_vm.modalType,"timeIsOut":_vm.timeIsOut},on:{"closeModal":_vm.closeModal,"clearGame":_vm.clearGame,"validGoNextButton":_vm.validGoNextButton}})]:_vm._e()],2)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('ModalActionsChallenge',{attrs:{"questionsCount":!_vm.isReopenedQuestion
              ? _vm.selectedQuestions.length
              : _vm.reOpenQuestion.length,"passedQuestions":_vm.passedQuestions,"disabledGoNext":_vm.isReopenedQuestion ? !_vm.isReopenedQuestion : !_vm.allDone,"memoryTimeIsOut":_vm.memoryTimeIsOut,"timeIsOut":_vm.timeIsOut},on:{"goBack":_vm.goBack,"goNext":_vm.goNext}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }