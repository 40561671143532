<template>
  <v-dialog
    v-model="dialogIsOpen"
    persistent
    width="80vw"
    max-width="700"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-if="dialogSummaryIsOpen">
      <ChallengesSummary
        :title="levelTitle"
        @closeModal="closeModal"
        @closeChallenge="closeModal"
        @updateList="updateList"
        @repeatChallenge="repeatChallenge"
        @reOpenQuestion="getOpenQuestion"
        :unitTitle="unitTitle"
        :summaryInfo="summaryInfo"
        :summaryInfoLinks="summaryInfoLinks"
      />
    </template>

    <template v-else>
      <v-card
        elevation="0"
        class="ak-question pa-0"
        v-for="(item, index) in questionList"
        :key="index"
        :class="{
          'current-question': index === passedQuestions - 1,
        }"
      >
        <v-card-text class="px-0">
          <v-container class="pt-4 pt-md-6 px-4">
            <ModalTitleChallenge
              :isReopenedQuestion="isReopenedQuestion"
              @closeModal="closeModal"
              :isCurrentQuestion="index === passedQuestions - 1"
              @memoryTimeOut="memoryTimeOut"
              @timeOut="timeOut"
              :isStartMemoryTimer="isStartMemoryTimer"
              :title="isReopenedQuestion ? item.data.name : item.name"
              :description="isReopenedQuestion ? item.data.description : null"
              :levelTitle="levelTitle"
              :unitTitle="unitTitle"
              :isMemory="
                (isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
              "
              :timeAvailable="timeAvailable"
              :timeSpent="timeSpent"
              :timingMemory="
                (isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
                  ? item.timeAvailable
                  : 0
              "
              :timingMemorySpent="
                (isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
                  ? item.timeSpent
                  : 0
              "
            />
          </v-container>
          <v-container>
            <template
              v-if="
                (isReopenedQuestion ? item.data.type : item.type) ===
                'MULTI_CHOICE'
              "
            >
              <TextQuestion
                :questionAnswers="
                  isReopenedQuestion
                    ? item.data.answerSummaries
                    : item.answerOptions
                "
                :questionAnswersTaked="item.answers"
                :questionPostLink="item.links | getPostLink"
                :passedQuestions="passedQuestions"
                :isReopenedQuestion="isReopenedQuestion"
                :modalType="modalType"
                @closeModal="closeModal"
                @clearGame="clearGame"
                @validGoNextButton="validGoNextButton"
                :timeIsOut="timeIsOut"
              />
            </template>

            <template
              v-if="
                (isReopenedQuestion ? item.data.type : item.type) === 'MEMORY'
              "
            >
              <MemoryQuestion
                :questionAnswersTaked="item.answers"
                :questionAnswers="
                  isReopenedQuestion
                    ? item.data.answerSummaries
                    : item.answerOptions
                "
                :questionPostLink="item.links | getPostLink"
                :passedQuestions="passedQuestions"
                :isReopenedQuestion="isReopenedQuestion"
                :modalType="modalType"
                @closeModal="closeModal"
                @clearGame="clearGame"
                @validGoNextButton="validGoNextButton"
                :memoryTimeIsOut="item.timeIsOf || memoryTimeIsOut"
                @startMemoryTimer="startMemoryTimer"
                @memoryTimeOut="memoryTimeOut"
                :timeIsOut="timeIsOut"
              />
            </template>

            <template
              v-if="
                (isReopenedQuestion ? item.data.type : item.type) === 'IMAGE'
              "
            >
              <PictureQuestion
                :questionAnswersTaked="item.answers"
                :questionPicture="
                  isReopenedQuestion ? item.data.imageSrc : item.imageSrc
                "
                :questionAnswers="
                  isReopenedQuestion
                    ? item.data.answerSummaries
                    : item.answerOptions
                "
                :questionPostLink="item.links | getPostLink"
                :passedQuestions="passedQuestions"
                :isReopenedQuestion="isReopenedQuestion"
                :modalType="modalType"
                @closeModal="closeModal"
                @clearGame="clearGame"
                @validGoNextButton="validGoNextButton"
                :timeIsOut="timeIsOut"
              />
            </template>

            <template
              v-if="
                (isReopenedQuestion ? item.data.type : item.type) === 'PUZZLE'
              "
            >
              <PuzzleQuestion
                :questionAnswersTaked="item.answers"
                :questionAnswers="
                  isReopenedQuestion
                    ? item.data.answerSummaries
                    : item.answerOptions
                "
                :questionPostLink="item.links | getPostLink"
                :passedQuestions="passedQuestions"
                :isReopenedQuestion="isReopenedQuestion"
                :modalType="modalType"
                @closeModal="closeModal"
                @clearGame="clearGame"
                @validGoNextButton="validGoNextButton"
                :timeIsOut="timeIsOut"
              />
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <ModalActionsChallenge
            :questionsCount="
              !isReopenedQuestion
                ? selectedQuestions.length
                : reOpenQuestion.length
            "
            :passedQuestions="passedQuestions"
            :disabledGoNext="
              isReopenedQuestion ? !isReopenedQuestion : !allDone
            "
            :memoryTimeIsOut="memoryTimeIsOut"
            :timeIsOut="timeIsOut"
            @goBack="goBack"
            @goNext="goNext"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import ModalActionsChallenge from '@/components/modules/games/common/ModalActionsChallenge';
import ModalTitleChallenge from '@/components/modules/games/common/ModalTitleChallenge';
import TextQuestion from '@/components/modules/games/TextQuestion/TextQuestion';
import MemoryQuestion from '@/components/modules/games/MemoryQuestion/MemoryQuestion';
import PictureQuestion from '@/components/modules/games/PictureQuestion/PictureQuestion';
import PuzzleQuestion from '@/components/modules/games/PuzzleQuestion/PuzzleQuestion';
import ChallengesSummary from '@/components/modules/games/ChallengesSummary';

export default {
  name: 'ChallengeModal',
  components: {
    ModalActionsChallenge,
    ModalTitleChallenge,
    TextQuestion,
    MemoryQuestion,
    PictureQuestion,
    PuzzleQuestion,
    ChallengesSummary,
  },

  props: {
    levelTitle: {
      type: String,
      default: null,
    },
    dialogIsOpen: {
      type: Boolean,
      default: false,
    },
    timeAvailable: {
      type: Number,
      default: null,
    },
    timeSpent: {
      type: Number,
      default: null,
    },
    selectedQuestions: {
      type: Array,
      default: () => [],
    },
    unitId: {
      type: String,
      required: true,
    },
    gameIsStart: {
      type: Boolean,
      default: false,
    },
    challengeLinkGet: {
      type: String,
      default: null,
    },
    challengeLinkPostRun: {
      type: String,
      default: null,
    },
    challengeLinkGetSummary: {
      type: String,
      default: null,
    },
    postCompleteRunLink: {
      type: String,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    reopenChallenge: {
      type: Boolean,
      default: false,
    },
    gameId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      allDone: false,
      passedQuestions: 1,
      dialogSummaryIsOpen: false,
      summaryInfo: null,
      summaryInfoLinks: null,
      challengeLinkGetSummaryTmp: null,
      memoryTimeIsOut: false,
      timeIsOut: false,
      isStartMemoryTimer: false,

      reOpenQuestion: [],
      isReopenedQuestion: false,
      unitTitle: '',
    };
  },

  computed: {
    questionList() {
      return !this.isReopenedQuestion && this.selectedQuestions.length > 0
        ? this.selectedQuestions
        : this.reOpenQuestion;
    },
  },

  filters: {
    getPostLink(val) {
      let tmp = val.filter((el) => el.method == 'POST' && el.rel == 'answer');
      return tmp[0]?.href || '';
    },
  },

  created() {
    this.getUnitInfo();
    if (Boolean(this.challengeLinkGetSummary) && !this.reopenChallenge) {
      this.getChallengeSummary();
    }
  },

  methods: {
    async getUnitInfo() {
      this.$axios
        .get(`/api/v1/user/learningunits/${this.unitId}`, this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            this.unitTitle = res.data.data.name;
          }
        });

      this.$forceUpdate();
    },

    async getOpenQuestion(idQuestion) {
      this.dialogSummaryIsOpen = false;
      this.reOpenQuestion = [];
      this.isReopenedQuestion = true;
      this.passedQuestions = 1;

      await this.$axios
        .get(
          Boolean(this.challengeLinkGetSummaryTmp)
            ? `${this.challengeLinkGetSummaryTmp}/${idQuestion}?page=0&limit=100`
            : `${this.challengeLinkGetSummary}/${idQuestion}?page=0&limit=100`,

          this.axiosGetConfig
        )
        .then((res) => {
          if (Boolean(res.data.data) && res.data.data.length > 0) {
            let runInfo = null;
            runInfo = res.data.data;
            let tmp = [];
            runInfo.forEach((el) => {
              tmp.push(el);
            });
            this.reOpenQuestion = tmp;
          }
        })
        .catch((err) => {
          console.log('AXIOS ERROR finish challenge run: ', err);
        });

      this.$forceUpdate();
    },

    startMemoryTimer() {
      this.isStartMemoryTimer = true;
    },

    memoryTimeOut() {
      this.memoryTimeIsOut = true;
      this.selectedQuestions.map((item, index) => {
        if (index === this.passedQuestions - 1) {
          item.timeIsOf = true;
        }
      });
    },

    timeOut() {
      this.timeIsOut = true;
    },

    validGoNextButton(val) {
      this.allDone = val;
    },

    closeChallenge() {
      this.$emit('updateList');
      this.$emit('closeModal');
    },

    closeReopen() {
      this.isReopenedQuestion = false;
      this.dialogSummaryIsOpen = true;
      this.reOpenQuestion = [];
    },

    clearGame() {
      this.passedQuestions = 1;
    },

    closeModal() {
      if (this.isReopenedQuestion) {
        this.closeReopen();
        this.clearGame();
      } else {
        this.$emit('closeModal');
      }
    },

    async finishrun() {
      await this.$axios
        .put(this.postCompleteRunLink, this.axiosPostPutConfig)
        .then((res) => {
          console.log('AXIOS SUCCESS finish challenge run: ', res.statusText);
          this.challengeLinkGetSummaryTmp = `${this.postCompleteRunLink}/summary`;
          this.getChallengeSummary();
        })
        .catch((err) => {
          console.log('AXIOS ERROR finish challenge run: ', err);
        });

      this.updateUserInfo();

      this.$forceUpdate();
    },

    updateUserInfo() {
      this.$store.dispatch('user/loadUserData');
    },

    goBack() {
      if (this.passedQuestions === 1) {
        this.closeModal();
      } else {
        this.isStartMemoryTimer = true;
        this.passedQuestions -= 1;
      }
    },

    goNext() {
      if (
        this.passedQuestions ===
        (!this.isReopenedQuestion
          ? this.selectedQuestions.length
          : this.reOpenQuestion.length)
      ) {
        if (this.isReopenedQuestion) {
          this.closeReopen();
          this.clearGame();
          // return false;
        } else {
          this.finishrun();
          return false;
        }
      } else {
        this.memoryTimeIsOut = false;
        this.isStartMemoryTimer = false;
        this.passedQuestions += 1;
      }
    },

    repeatChallenge() {
      this.isReopenedQuestion = false;
      this.dialogSummaryIsOpen = false;
      this.timeIsOut = false;
      this.$emit('repeatChallenge', this.gameId);
    },

    getChallengeSummary() {
      this.$axios
        .get(
          Boolean(this.challengeLinkGetSummaryTmp)
            ? this.challengeLinkGetSummaryTmp
            : this.challengeLinkGetSummary,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.status === 200) {
            this.dialogSummaryIsOpen = true;
            this.summaryInfo = res.data.data;
            this.summaryInfoLinks = res.data.links;
          }
        });
      this.$forceUpdate();
    },

    updateList() {
      this.$emit('updateList');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__text {
  padding-bottom: 82px !important;
  /* padding-top: 110px !important; */
  min-height: 170px;

  &.ak-challenge-page {
    padding-bottom: 200px !important;
    padding-top: 17px !important;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding-bottom: 150px !important;
    }
  }
}

::v-deep .v-card__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--v-primary-base);
  z-index: 1;
  @media (min-width: map-get($grid-breakpoints, md)) {
    position: absolute;
  }
}

::v-deep .ak-question {
  background-color: var(--v-primary-base);

  &-wrapper {
    height: calc(100vh - 75px);
    background-color: var(--v-primary-base);
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      position: relative;
      height: auto;
    }
  }
}

.v-card {
  display: none;

  &.current-question {
    display: block;
    position: relative;
  }
}

::v-deep .v-dialog--fullscreen {
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
</style>
