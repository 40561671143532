var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:(("padding-top: " + _vm.paddingTop + "px")),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.questionAnswers),function(item,index){return _c('QuestionItem',{key:index,class:[
        { 'blocked-all': _vm.timeIsOut },
        !_vm.isReopenedQuestion && _vm.modalType === 'challenge' && _vm.isSelected(item)
          ? 'selected-answer'
          : _vm.isReopenedQuestion
          ? _vm.isCorrect(item)
            ? 'correct-answer'
            : ''
          : _vm.isSelected(item)
          ? _vm.isCorrect(item)
            ? 'correct-answer'
            : 'incorrect-answer'
          : '' ],attrs:{"text":item.name,"isUserSelected":_vm.isReopenedQuestion && _vm.isSelected(item)},on:{"selectAnswer":function($event){return _vm.selectAnswer(item)}}})}),1),(_vm.isReopenedQuestion)?[_c('v-col',{staticClass:"d-flex flex-row align-center mb-3",attrs:{"cols":"12"}},[_c('mdicon',{staticClass:"ak-icon mr-2",attrs:{"name":"AccountCircle","size":"26"}}),_c('p',{staticClass:"ak-hint ak-colorText-sandWhite ma-0"},[_vm._v("Ihre Antworten")])],1),_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"ak-correct-answer-hint mr-2"}),_c('p',{staticClass:"ak-hint ak-colorText-sandWhite ma-0"},[_vm._v("Korrekte Lösung")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }