<template>
  <v-row
    no-gutters
    :justify="isReopenedQuestion ? 'center' : 'space-between'"
    align="center"
  >
    <template v-if="isReopenedQuestion">
      <AKButton
        @click="goBack()"
        :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
        :width="$vuetify.breakpoint.mdAndDown ? '100%' : '200'"
        :color="$vuetify.theme.themes.light.brightBlue"
        title="Ok, verstanden"
      />
    </template>
    <template v-else>
      <v-col cols="auto">
        <AKButton
          @click="goBack()"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100' : '115'"
          title="Zurück"
        />
      </v-col>
      <v-col cols="2" sm="1" class="ak-question-counter">
        {{ passedQuestions }}/ {{ questionsCount }}
      </v-col>

      <v-col cols="auto">
        <AKButton
          @click="goNext()"
          :isDisabled="disabledGoNext"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100' : '115'"
          title="Weiter"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'ModalActions',
  components: { AKButton },
  props: {
    disabledGoNext: {
      type: Boolean,
      default: true,
    },
    passedQuestions: {
      type: Number,
      default: 1,
    },
    questionsCount: {
      type: Number,
      default: 1,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    goNext() {
      this.$emit('goNext');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question-counter {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: center;
}
</style>
