<template>
  <v-row
    no-gutters
    :justify="isMemoryGame ? 'center' : 'space-between'"
    align="center"
  >
    <template v-if="!isMemoryGame">
      <v-col cols="auto">
        <AKButton
          @click="goBack()"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100' : '115'"
          title="Zurück"
        />
      </v-col>
      <v-col cols="2" sm="1" class="ak-question-counter">
        {{ passedQuestions }} / {{ questionsCount }}
      </v-col>
    </template>

    <v-col cols="auto">
      <template v-if="memoryTimeIsOut || timeIsOut">
        <AKButton
          @click="goNext()"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '120' : '135'"
          :title="
            questionsCount === passedQuestions ? 'Fertig' : 'Überspringen'
          "
        />
      </template>
      <template v-else>
        <AKButton
          @click="goNext()"
          :isDisabled="disabledGoNext"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100' : '115'"
          :title="questionsCount === passedQuestions ? 'Fertig' : 'Weiter'"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'ModalActionsChallenge',
  components: { AKButton },
  props: {
    disabledGoNext: {
      type: Boolean,
      default: true,
    },
    passedQuestions: {
      type: Number,
      default: 1,
    },
    questionsCount: {
      type: Number,
      default: 1,
    },
    isMemoryGame: {
      type: Boolean,
      default: false,
    },
    memoryTimeIsOut: {
      type: Boolean,
      default: false,
    },
    timeIsOut: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    goNext() {
      this.$emit('goNext');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question-counter {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: center;
}
</style>
