var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ak-puzzle-content",style:(("padding-top: " + _vm.paddingTop + "px")),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},_vm._l((_vm.gameSuggestions),function(item,index){return _c('div',{key:index,staticClass:"ak-puzzle-tile suggestions",class:[
          _vm.isReopenedQuestion
            ? _vm.isCorrect(item)
              ? 'tile-correct'
              : 'tile-incorrect'
            : _vm.$vuetify.breakpoint.smAndDown
            ? _vm.modalType === 'challenge' && _vm.hasAnswerMobile(item.id)
              ? 'tile-selected'
              : _vm.mobileIsValidSuggestion(item.id)
              ? 'tile-correct'
              : ''
            : _vm.modalType === 'challenge' && _vm.isSelected(item)
            ? 'tile-selected'
            : _vm.isCorrect(item)
            ? 'tile-correct'
            : '',
          _vm.isTouchDevice
            ? 'ak-puzzle-mobile-height'
            : 'ak-puzzle-desktop-height' ]},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('v-col',{staticClass:"d-flex flex-column align-end",attrs:{"cols":"6"}},[(_vm.isReopenedQuestion)?_vm._l((_vm.gameAnswers),function(item,index){return _c('div',{key:index,staticClass:"ak-puzzle-tile suggestions",class:[
            _vm.isSelected(item)
              ? _vm.isCorrect(item)
                ? 'tile-correct'
                : 'tile-incorrect'
              : '',
            _vm.isTouchDevice
              ? 'ak-puzzle-mobile-height'
              : 'ak-puzzle-desktop-height' ]},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}):(_vm.isTouchDeviceChallengeMode)?_vm._l((_vm.mobileAnsweredQuestions),function(item,index){return _c('div',{key:index,staticClass:"ak-puzzle-tile ak-puzzle-mobile-height suggestions",class:[
            { 'blocked-all': _vm.timeIsOut },
            _vm.modalType === 'challenge' && _vm.hasAnswerMobile(item.suggestionId)
              ? 'tile-selected-but-movable'
              : _vm.mobileIsValidSuggestion(item.suggestionId)
              ? 'tile-correct second'
              : _vm.isSelectedSuggestionMobile(item.suggestionId)
              ? 'tile-selected'
              : 'tile-empty' ],on:{"click":function($event){return _vm.selectSuggestionMobileForChallenge(item.suggestionId, $event)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.findNameByAnswerId(item.answerId))+" ")])])}):(_vm.isTouchDevice)?_vm._l((_vm.gameSuggestions),function(item,index){return _c('div',{key:index,staticClass:"ak-puzzle-tile ak-puzzle-mobile-height suggestions",class:[
            { 'blocked-all': _vm.timeIsOut },
            _vm.modalType === 'challenge' && _vm.mobileIsValidSuggestion(item.id)
              ? 'tile-selected'
              : _vm.mobileIsValidSuggestion(item.id)
              ? 'tile-correct second'
              : _vm.isSelectedSuggestionMobile(item.id)
              ? 'tile-selected'
              : 'tile-empty' ],on:{"click":function($event){return _vm.selectSuggestionMobile(item, $event)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.setNameMobile(item))+" ")])])}):(_vm.isChallengeMode)?_vm._l((_vm.answeredQuestions),function(answer,i){return _c('drop',{key:i,ref:("drop-" + (answer.suggestionId)),refInFor:true,staticClass:"ak-puzzle-tile ak-puzzle-desktop-height suggestions",class:[
            { 'blocked-all': _vm.timeIsOut },
            _vm.hasAnswer(answer) ? 'tile-selected-but-movable' : 'tile-empty' ],on:{"drop":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDropWithList.apply(void 0, [ answer ].concat( argsArray ))}}},_vm._l((answer.answerList),function(item){return _c('drag',{key:item,ref:("drag-" + item),refInFor:true,staticClass:"ak-puzzle-tile ak-puzzle-desktop-height dropped-answer",class:{ 'blocked-all': _vm.timeIsOut },attrs:{"transfer-data":{ item: item, list: answer.answerList }}},[_c('span',[_vm._v(" "+_vm._s(_vm.findNameByAnswerId(item))+" ")])])}),1)}):_vm._l((_vm.gameSuggestions),function(item,index){return _c('drop',{key:index,ref:item.id,refInFor:true,staticClass:"ak-puzzle-tile suggestions",class:[
            { 'blocked-all': _vm.timeIsOut },
            _vm.modalType === 'challenge' && _vm.isSelected(item)
              ? 'tile-selected'
              : _vm.isCorrect(item)
              ? 'tile-correct second'
              : 'tile-empty',
            _vm.isTouchDevice
              ? 'ak-puzzle-mobile-height'
              : 'ak-puzzle-desktop-height' ],on:{"drop":function (data, event) {
              _vm.handleDrop(data, item, event);
            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.setName(item))+" ")])])})],2)],1),(_vm.isReopenedQuestion)?[_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mr-3",attrs:{"contain":"","height":"28","max-width":"56","src":_vm.correct}}),_c('p',{staticClass:"ak-hint"},[_vm._v("Korrekt gelöst")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mr-3",attrs:{"contain":"","height":"28","max-width":"56","src":_vm.error}}),_c('p',{staticClass:"ak-hint"},[_vm._v("Falsch gelöst")])],1)],1)]:[_c('v-row',{staticClass:"ak-puzzle-answer-wrapper",attrs:{"no-gutters":""}},[(_vm.isTouchDeviceChallengeMode)?_vm._l((_vm.gameAnswers),function(item,index){return _c('div',{key:index,ref:("mobile-answer-" + (item.id)),refInFor:true,staticClass:"ak-puzzle-tile ak-puzzle-mobile-height answer",class:[
            { 'blocked-all': _vm.timeIsOut },
            {
              'tile-selected tile-disable': _vm.isSelectedForMobileChallenge(
                item.id
              ),
            } ],on:{"click":function($event){return _vm.selectAnswerMobileForChallenge(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}):(_vm.isTouchDevice)?_vm._l((_vm.gameAnswers),function(item,index){return _c('div',{key:index,staticClass:"ak-puzzle-tile ak-puzzle-mobile-height answer",class:[
            { 'blocked-all': _vm.timeIsOut },
            { 'tile-disable': _vm.mobileIsValidAnswers(item.id) },
            _vm.modalType === 'challenge' && _vm.isSelected(item)
              ? 'tile-selected tile-disable'
              : _vm.mobileIsValidAnswers(item.id)
              ? 'tile-correct second'
              : _vm.isSelectedAnswerMobile(item.id)
              ? 'tile-selected tile-disable'
              : '' ],on:{"click":function($event){return _vm.selectAnswerMobile(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}):(_vm.isChallengeMode)?_vm._l((_vm.gameAnswers),function(item,index){return _c('drag',{key:index,ref:("drag-" + (item.id)),refInFor:true,staticClass:"ak-puzzle-tile ak-puzzle-desktop-height answer",class:{ 'blocked-all': _vm.timeIsOut },attrs:{"transfer-data":{ item: item.id, list: [] }}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}):_vm._l((_vm.gameAnswers),function(item,index){return _c('drag',{key:index,ref:("drag-" + (item.id)),refInFor:true,staticClass:"ak-puzzle-tile ak-puzzle-desktop-height answer",class:[
            { 'blocked-all': _vm.timeIsOut },
            _vm.isTouchDevice
              ? 'ak-puzzle-mobile-height'
              : 'ak-puzzle-desktop-height' ],attrs:{"transfer-data":item.id}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }