<template>
  <p class="ak-question-answer" @click="selectAnswer">
    <mdicon
      v-if="isUserSelected"
      class="ak-icon user-select"
      name="AccountCircle"
      size="26"
    />
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'QuestionItem',
  props: {
    text: {
      type: String,
      default: null,
    },
    isUserSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectAnswer() {
      this.$emit('selectAnswer');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question-answer {
  position: relative;
  background-color: var(--v-secondary-base);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--v-text-base);
  padding: 16px 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  .user-select {
    position: absolute;
    left: 10px;
  }
}
</style>
