<template>
  <v-row no-gutters :style="`padding-top: ${paddingTop}px`">
    <v-col cols="12">
      <v-img
        position="center"
        :max-height="$vuetify.breakpoint.lgAndDown ? 275 : 350"
        :max-width="$vuetify.breakpoint.lgAndDown ? 319 : 450"
        contain
        class="ak-question-image mx-auto"
        :src="questionPicture"
        alt=""
      />
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between flex-wrap">
      <QuestionItem
        v-for="(item, index) in questionAnswers"
        :key="index"
        :text="item.name"
        :class="[
          { 'blocked-all': timeIsOut },
          !isReopenedQuestion && modalType === 'challenge' && isSelected(item)
            ? 'selected-answer'
            : isReopenedQuestion
            ? isCorrect(item)
              ? 'correct-answer'
              : isSelected(item)
              ? 'incorrect-answer'
              : ''
            : isSelected(item)
            ? isCorrect(item)
              ? 'correct-answer'
              : 'incorrect-answer'
            : '',
        ]"
        @selectAnswer="selectAnswer(item)"
      />
    </v-col>
  </v-row>
</template>

<script>
import QuestionItem from '@/components/modules/games/common/QuestionItem';

export default {
  name: 'PictureQuestion',
  components: { QuestionItem },
  props: {
    questionAnswersTaked: {
      type: Array,
      default: () => [],
    },
    questionPicture: {
      type: String,
      default: null,
    },
    questionAnswers: {
      type: Array,
      default: () => [],
    },
    questionPostLink: {
      type: String,
      default: null,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
    passedQuestions: {
      type: Number,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
    timeIsOut: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeIndex: [],
      takedAnswer: null,
      selectedAnswers: [],
      paddingTop: 0,
      correctAnswer: [],
    };
  },

  watch: {
    allDone(val) {
      this.$emit('validGoNextButton', val);
    },
  },

  created() {
    this.questionAnswersTaked.forEach((item) => {
      this.activeIndex.push(item.choice);
    });
  },

  computed: {
    allDone() {
      if (this.modalType === 'challenge') {
        return this.activeIndex.length === 1;
      } else {
        return (
          this.correctAnswer.length > 0 &&
          this.correctAnswer.every((elem) => this.activeIndex.includes(elem))
        );
      }
    },
  },

  methods: {
    isCorrect(item) {
      if (this.isReopenedQuestion) {
        if ('correct' in item) {
          return item.correct;
        } else {
          return false;
        }
      } else {
        return item.isCorrect;
      }
    },

    isSelected(item) {
      if (this.isReopenedQuestion) {
        if ('given' in item) {
          return item.given;
        } else {
          return false;
        }
      } else {
        return Boolean(this.activeIndex.includes(item.id));
      }
    },

    removeId(val, array) {
      var new_keys = array.filter((item) => item !== val);
      return new_keys;
    },

    async selectAnswer(item) {
      if (this.modalType === 'challenge') {
        this.takedAnswer = item;
        let tmpIndex = item.id;

        const answer = {
          choice: item.id,
          type: 'SINGLE',
        };

        await this.$axios
          .post(this.questionPostLink, answer, this.axiosPostPutConfig)
          .then((res) => {
            this.questionAnswers.forEach((el) => {
              if (el.id === item.id) {
                el.isSelected = true;
              }
            });
          });

        this.$forceUpdate();

        if (!this.selectedAnswers.includes(item.id)) {
          if (!this.activeIndex.includes(item.id)) {
            this.activeIndex = [];
            this.activeIndex.push(item.id);
          }
          this.selectedAnswers = [];
          this.selectedAnswers.push(item.id);
        } else {
          this.questionAnswers.forEach((el) => {
            if (el.id === item.id) {
              el.isSelected = false;
            }
          });

          this.activeIndex = this.activeIndex.filter(
            (item) => item !== tmpIndex
          );

          this.selectedAnswers = this.removeId(item.id, this.selectedAnswers);
        }

        this.takedAnswer = null;
      } else if (this.isReopenedQuestion) {
        return true;
      } else {
        this.takedAnswer = item;
        let tmpIndex = item.id;

        const answer = {
          choice: item.id,
          type: 'SINGLE',
        };

        await this.$axios
          .post(this.questionPostLink, answer, this.axiosPostPutConfig)
          .then((res) => {
            this.questionAnswers.forEach((el) => {
              if (el.id === item.id) {
                el.isCorrect = res.data.data.correct;
                el.isSelected = true;

                if (
                  res.data.data.correct &&
                  !this.correctAnswer.includes(res.data.data.choice)
                ) {
                  this.correctAnswer.push(res.data.data.choice);
                }
              }
            });
          });

        this.$forceUpdate();

        if (!this.selectedAnswers.includes(item.id)) {
          if (!this.activeIndex.includes(item.id)) {
            this.activeIndex.push(item.id);
          }

          this.selectedAnswers.push(item.id);

          if (!item.isCorrect) {
            setTimeout(() => {
              this.activeIndex = this.activeIndex.filter(
                (item) => item !== tmpIndex
              );

              this.selectedAnswers = this.removeId(
                item.id,
                this.selectedAnswers
              );
            }, 700);
          }
        } else {
          this.questionAnswers.forEach((el) => {
            if (el.id === item.id) {
              el.isSelected = false;
            }
          });

          this.activeIndex = this.activeIndex.filter(
            (item) => item !== tmpIndex
          );

          this.selectedAnswers = this.removeId(item.id, this.selectedAnswers);
        }

        this.takedAnswer = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question {
  background-color: var(--v-primary-base);
  position: relative;

  &-title {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 4px;
  }

  &-text {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--v-secondary-base);
  }

  &-answer {
    background-color: var(--v-secondary-base);
    box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--v-text-base);
    padding: 16px 10px;
    text-align: center;
    cursor: pointer;
    width: 45%;
    min-width: 45%;
    margin: 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-image {
    max-width: inherit;
    margin-bottom: 26px;
    display: block;
    border-radius: 10px;
    overflow: hidden;
  }
}

::v-deep .ak-question-answer {
  &.correct-answer {
    background-color: var(--v-success-base);
    color: #fff;
  }

  &.incorrect-answer {
    background-color: var(--v-error-base);
    color: #fff;
  }

  &.disabled-answer:not(.incorrect-answer):not(.correct-answer) {
    background-color: var(--v-primary-lighten5);
  }

  &.selected-answer {
    background-color: var(--v-accent-base);
  }
}

.blocked-all {
  pointer-events: none;
}
</style>
