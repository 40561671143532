<template>
  <v-col
    cols="3"
    class="px-0 ak-timecounter"
    :class="{ 'ak-memory': isMemory, 'time-isOut': timeIsOut }"
  >
    <p class="font-roboto font23-600 mb-0">
      {{ isMemory ? getTimeMemory : getTime }}
    </p>
    <template v-if="isMemory">
      <p class="font-roboto font12-400 mb-0">Memory</p>
    </template>
  </v-col>
</template>

<script>
export default {
  name: 'CountDownTimer',
  props: {
    isCurrentQuestion: {
      type: Boolean,
      default: false,
    },
    timing: {
      type: Number,
      default: 0,
    },
    timingMemory: {
      type: Number,
      default: 0,
    },
    isMemory: {
      type: Boolean,
      default: false,
    },
    isStartMemoryTimer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timeIsOut: false,
      secondsTotal: this.timing,
      secondsTotalMemory: this.timingMemory,
    };
  },

  watch: {
    isCurrentQuestion(val) {
      if (val) {
        this.countDownTimerMemory();
      }
    },

    isStartMemoryTimer(val) {
      if (val) {
        this.countDownTimerMemory();
      }
    },
  },

  methods: {
    countDownTimer() {
      if (this.timing === 0) {
        return false;
      } else {
        if (this.secondsTotal > 0) {
          setTimeout(() => {
            this.secondsTotal -= 1;
            this.countDownTimer();
          }, 1000);
        } else {
          this.$emit('timeOut', true);
        }
      }
    },

    countDownTimerMemory() {
      if (this.timingMemory === 0) {
        if (this.isCurrentQuestion) {
          this.timeIsOut = true;
          this.$emit('memoryTimeOut', true);
        } else {
          return false;
        }
      } else {
        if (this.isCurrentQuestion && this.isStartMemoryTimer) {
          if (this.secondsTotalMemory > 0) {
            setTimeout(() => {
              this.secondsTotalMemory -= 1;
              this.countDownTimerMemory();
            }, 1000);
          } else {
            this.timeIsOut = true;
            this.$emit('memoryTimeOut', true);
          }
        }
      }
    },
  },

  created() {
    this.countDownTimer();
    if (this.isMemory && this.isStartMemoryTimer) {
      this.countDownTimerMemory();
    }
  },

  computed: {
    getTime() {
      if (this.timing === 0) {
        return '00:00';
      } else {
        let d = Number(this.secondsTotal);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? ':' : ':') : '';
        let mDisplay = m > 0 ? m + ':' : '';
        let sDisplay = s > 0 ? (s > 9 ? s : '0' + s) : '00';
        return hDisplay + mDisplay + sDisplay;
      }
    },
    getTimeMemory() {
      if (this.timingMemory === 0) {
        return '00:00';
      } else {
        let d = Number(this.secondsTotalMemory);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? ':' : ':') : '';
        let mDisplay = m > 0 ? m + ':' : '';
        let sDisplay = s > 0 ? (s > 9 ? s : '0' + s) : '00';
        return hDisplay + mDisplay + sDisplay;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-timecounter {
  background-color: rgba(239, 239, 239, 0.5);
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  min-height: 39px;
  min-width: 77px;
  position: absolute;
  left: 0;
  padding: 6px 4px;

  &.ak-memory {
    top: 50px;

    &.time-isOut {
      background-color: red;
    }
  }
}
</style>
