<template>
  <div>
    <v-row
      no-gutters
      class="ak-puzzle-content"
      :style="`padding-top: ${paddingTop}px`"
    >
      <v-col cols="6" class="d-flex flex-column">
        <div
          v-for="(item, index) in gameSuggestions"
          :key="index"
          class="ak-puzzle-tile suggestions"
          :class="[
            isReopenedQuestion
              ? isCorrect(item)
                ? 'tile-correct'
                : 'tile-incorrect'
              : $vuetify.breakpoint.smAndDown
              ? modalType === 'challenge' && hasAnswerMobile(item.id)
                ? 'tile-selected'
                : mobileIsValidSuggestion(item.id)
                ? 'tile-correct'
                : ''
              : modalType === 'challenge' && isSelected(item)
              ? 'tile-selected'
              : isCorrect(item)
              ? 'tile-correct'
              : '',
            isTouchDevice
              ? 'ak-puzzle-mobile-height'
              : 'ak-puzzle-desktop-height',
          ]"
        >
          <span>
            {{ item.name }}
          </span>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex flex-column align-end">
        <template v-if="isReopenedQuestion">
          <div
            v-for="(item, index) in gameAnswers"
            :key="index"
            class="ak-puzzle-tile suggestions"
            :class="[
              isSelected(item)
                ? isCorrect(item)
                  ? 'tile-correct'
                  : 'tile-incorrect'
                : '',
              isTouchDevice
                ? 'ak-puzzle-mobile-height'
                : 'ak-puzzle-desktop-height',
            ]"
          >
            <span>
              {{ item.name }}
            </span>
          </div>
        </template>
        <template v-else-if="isTouchDeviceChallengeMode">
          <div
            v-for="(item, index) in mobileAnsweredQuestions"
            :key="index"
            class="ak-puzzle-tile ak-puzzle-mobile-height suggestions"
            :class="[
              { 'blocked-all': timeIsOut },
              modalType === 'challenge' && hasAnswerMobile(item.suggestionId)
                ? 'tile-selected-but-movable'
                : mobileIsValidSuggestion(item.suggestionId)
                ? 'tile-correct second'
                : isSelectedSuggestionMobile(item.suggestionId)
                ? 'tile-selected'
                : 'tile-empty',
            ]"
            @click="
              selectSuggestionMobileForChallenge(item.suggestionId, $event)
            "
          >
            <span>
              {{ findNameByAnswerId(item.answerId) }}
            </span>
          </div>
        </template>
        <template v-else-if="isTouchDevice">
          <div
            v-for="(item, index) in gameSuggestions"
            :key="index"
            class="ak-puzzle-tile ak-puzzle-mobile-height suggestions"
            :class="[
              { 'blocked-all': timeIsOut },
              modalType === 'challenge' && mobileIsValidSuggestion(item.id)
                ? 'tile-selected'
                : mobileIsValidSuggestion(item.id)
                ? 'tile-correct second'
                : isSelectedSuggestionMobile(item.id)
                ? 'tile-selected'
                : 'tile-empty',
            ]"
            @click="selectSuggestionMobile(item, $event)"
          >
            <span>
              {{ setNameMobile(item) }}
            </span>
          </div>
        </template>
        <template v-else-if="isChallengeMode">
          <drop
            v-for="(answer, i) in answeredQuestions"
            :key="i"
            class="ak-puzzle-tile ak-puzzle-desktop-height suggestions"
            :class="[
              { 'blocked-all': timeIsOut },
              hasAnswer(answer) ? 'tile-selected-but-movable' : 'tile-empty',
            ]"
            @drop="handleDropWithList(answer, ...arguments)"
            :ref="`drop-${answer.suggestionId}`"
          >
            <drag
              v-for="item in answer.answerList"
              :key="item"
              :transfer-data="{ item: item, list: answer.answerList }"
              :ref="`drag-${item}`"
              class="ak-puzzle-tile ak-puzzle-desktop-height dropped-answer"
              :class="{ 'blocked-all': timeIsOut }"
            >
              <span>
                {{ findNameByAnswerId(item) }}
              </span>
            </drag>
          </drop>
        </template>
        <template v-else>
          <drop
            v-for="(item, index) in gameSuggestions"
            :key="index"
            class="ak-puzzle-tile suggestions"
            :class="[
              { 'blocked-all': timeIsOut },
              modalType === 'challenge' && isSelected(item)
                ? 'tile-selected'
                : isCorrect(item)
                ? 'tile-correct second'
                : 'tile-empty',
              isTouchDevice
                ? 'ak-puzzle-mobile-height'
                : 'ak-puzzle-desktop-height',
            ]"
            @drop="
              (data, event) => {
                handleDrop(data, item, event);
              }
            "
            :ref="item.id"
          >
            <span>
              {{ setName(item) }}
            </span>
          </drop>
        </template>
      </v-col>
    </v-row>

    <template v-if="isReopenedQuestion">
      <v-row no-gutters class="mb-5">
        <v-col cols="12" class="d-flex flex-row align-center">
          <v-img
            contain
            class="mr-3"
            height="28"
            max-width="56"
            :src="correct"
          />
          <p class="ak-hint">Korrekt gelöst</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="d-flex flex-row align-center">
          <v-img contain class="mr-3" height="28" max-width="56" :src="error" />
          <p class="ak-hint">Falsch gelöst</p>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters class="ak-puzzle-answer-wrapper">
        <template v-if="isTouchDeviceChallengeMode">
          <div
            v-for="(item, index) in gameAnswers"
            :key="index"
            :ref="`mobile-answer-${item.id}`"
            class="ak-puzzle-tile ak-puzzle-mobile-height answer"
            :class="[
              { 'blocked-all': timeIsOut },
              {
                'tile-selected tile-disable': isSelectedForMobileChallenge(
                  item.id
                ),
              },
            ]"
            @click="selectAnswerMobileForChallenge(item)"
          >
            <span>
              {{ item.name }}
            </span>
          </div>
        </template>
        <template v-else-if="isTouchDevice">
          <div
            v-for="(item, index) in gameAnswers"
            :key="index"
            class="ak-puzzle-tile ak-puzzle-mobile-height answer"
            :class="[
              { 'blocked-all': timeIsOut },
              { 'tile-disable': mobileIsValidAnswers(item.id) },
              modalType === 'challenge' && isSelected(item)
                ? 'tile-selected tile-disable'
                : mobileIsValidAnswers(item.id)
                ? 'tile-correct second'
                : isSelectedAnswerMobile(item.id)
                ? 'tile-selected tile-disable'
                : '',
            ]"
            @click="selectAnswerMobile(item)"
          >
            <span>
              {{ item.name }}
            </span>
          </div>
        </template>
        <template v-else-if="isChallengeMode">
          <drag
            v-for="(item, index) in gameAnswers"
            :transfer-data="{ item: item.id, list: [] }"
            :ref="`drag-${item.id}`"
            :key="index"
            class="ak-puzzle-tile ak-puzzle-desktop-height answer"
            :class="{ 'blocked-all': timeIsOut }"
          >
            <span>
              {{ item.name }}
            </span>
          </drag>
        </template>
        <template v-else>
          <drag
            v-for="(item, index) in gameAnswers"
            :transfer-data="item.id"
            :ref="`drag-${item.id}`"
            :key="index"
            class="ak-puzzle-tile ak-puzzle-desktop-height answer"
            :class="[
              { 'blocked-all': timeIsOut },
              isTouchDevice
                ? 'ak-puzzle-mobile-height'
                : 'ak-puzzle-desktop-height',
            ]"
          >
            <span>
              {{ item.name }}
            </span>
          </drag>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PuzzleQuestion',
  props: {
    questionAnswersTaked: {
      type: Array,
      default: () => [],
    },
    questionAnswers: {
      type: Array,
      default: () => [],
    },
    questionPostLink: {
      type: String,
      default: null,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
    passedQuestions: {
      type: Number,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
    timeIsOut: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: require('@/assets/images/error.svg'),
      correct: require('@/assets/images/correct.svg'),
      paddingTop: 0,
      correctCount: 0,
      gameAnswers: null,
      gameSuggestions: null,

      answeredQuestions: [],
      mobileAnsweredQuestions: [],

      mobileActionState: {
        selectedAnswerId: null,
        selectedQuestionId: null,
      },

      mobileSelectedAnswer: null,
      mobileSelectedSuggestion: null,
      mobileActiveIndexAnswer: [],
      mobileActiveIndexSuggestion: [],
      mobileIsValidAnswersArray: [],
      mobileIsValidSuggestionArray: [],
    };
  },

  watch: {
    passedQuestions: {
      immediate: true,
      handler(oldVal, newVal) {
        if (oldVal < newVal) {
          this.$emit('validGoNextButton', oldVal < newVal);
        } else {
          this.$emit('validGoNextButton', false);
        }
      },
    },

    correctCount(val) {
      if (this.isTouchDeviceChallengeMode) {
        if (!this.mobileAnsweredQuestions.some((el) => !Boolean(el.answerId))) {
          this.$emit('validGoNextButton', true);
        }
      } else if (this.isTouchDevice) {
        if (this.mobileIsValidSuggestionArray.length === 4) {
          this.$emit('validGoNextButton', true);
        }
      } else if (
        !this.answeredQuestions.some((ans) => ans.answerList.length === 0)
      ) {
        this.$emit('validGoNextButton', true);
      }
    },
  },

  mounted() {
    this.getAnswers();
    this.getSuggestions();
  },

  created() {
    this.getAnswers();
    this.getSuggestions();
    setTimeout(() => {
      this.updateAnswers();
    }, 500);
  },

  computed: {
    isChallengeMode() {
      return this.modalType === 'challenge';
    },

    isTouchDeviceChallengeMode() {
      return this.isChallengeMode && this.isTouchDevice;
    },
  },

  methods: {
    hasAnswer(answer) {
      return answer.answerList && answer.answerList.length !== 0;
    },

    hasAnswerMobile(item) {
      return Boolean(
        this.mobileAnsweredQuestions.filter((el) => el.suggestionId === item)[0]
          .answerId
      );
    },

    isSelectedForMobileChallenge(answerId) {
      return Boolean(
        this.mobileAnsweredQuestions.some((el) => el.answerId === answerId)
      );
    },

    checkAndEnableReadyButton() {
      if (!this.mobileAnsweredQuestions.some((el) => !Boolean(el.answerId))) {
        this.$emit('validGoNextButton', true);
      }
    },

    updateAnswers() {
      this.questionAnswersTaked.forEach((item) => {
        this.answeredQuestions.filter(
          (ans) => ans.suggestionId === item.choiceA
        ).answerList = [item.choiceB];

        this.correctCount += 1;
      });

      let dragElement = null;

      this.answeredQuestions.forEach((answerRow) => {
        let answerId = answerRow.answerList.first;
        let suggestionId = answerRow.suggestionId;

        let foundAnswer = this.gameAnswers.filter(
          (answer) => answer.id === answerId
        );
        if (foundAnswer) {
          foundAnswer.isCorrect = true;
          foundAnswer.isSelected = true;
          foundAnswer.idCombination = suggestionId;
        } else {
          console.error('Answer not found with ID: ' + answerId);
        }

        let foundSuggestion = this.gameSuggestions.filter(
          (suggestion) => suggestion.id === suggestionId
        );
        if (foundSuggestion) {
          foundSuggestion.isCorrect = true;
          foundSuggestion.isSelected = true;
          foundSuggestion.idCombination = suggestionId;
        } else {
          console.error('Suggestion not found with ID: ' + suggestionId);
        }

        if (this.$vuetify.breakpoint.lgAndUp) {
          if (this.$refs[`drag-${answerId}`]) {
            dragElement = this.$refs[`drag-${answerId}`][0].$el;
          }
          if (dragElement) {
            dragElement.classList.add('tile-empty');
          }
        }
      });
    },

    setName(val) {
      if (val) {
        if (this.modalType !== 'challenge') {
          if (this.isCorrect(val)) {
            let item = null;
            item = this.gameAnswers.filter((i) => i.idCombination === val.id);
            return item[0]?.name || '';
          } else {
            return '';
          }
        } else {
          let item = null;
          item = this.gameAnswers.filter((i) => i.idCombination === val.id);
          return item[0]?.name || '';
        }
      } else {
        return '';
      }
    },

    findNameByAnswerId(val) {
      if (val) {
        let item = null;
        item = this.gameAnswers.filter((i) => i.id === val);
        return item[0]?.name || '';
      } else if (this.isTouchDevice) {
        return 'Klick hier...';
      } else {
        return '';
      }
    },

    setNameMobile(val) {
      if (val) {
        if (this.modalType !== 'challenge') {
          if (this.mobileIsValidSuggestion(val.id)) {
            let item = null;
            item = this.gameAnswers.filter((i) => i.idCombination === val.id);
            return item[0]?.name || 'Klick hier...';
          } else {
            return 'Klick hier...';
          }
        } else {
          let item = null;
          item = this.gameAnswers.filter((i) => i.idCombination === val.id);
          return item[0]?.name || 'Klick hier...';
        }
      } else {
        return 'Klick hier...';
      }
    },

    isCorrect(item) {
      if (this.isReopenedQuestion) {
        if ('correct' in item) {
          return item.correct;
        } else {
          return false;
        }
      } else {
        return item.isCorrect;
      }
    },

    isSelected(item) {
      if (this.isReopenedQuestion) {
        if ('given' in item) {
          return item.given;
        } else {
          return false;
        }
      } else {
        return Boolean(
          this.answeredQuestions.some(
            (ans) => ans.suggestionId === item.id && ans.answerList.length > 0
          )
        );
      }
    },

    isSelectedAnswerMobile(index) {
      return Boolean(this.mobileActiveIndexAnswer.includes(index));
    },

    isSelectedSuggestionMobile(index) {
      return Boolean(this.mobileActiveIndexSuggestion.includes(index));
    },

    mobileIsValidAnswers(item) {
      return Boolean(this.mobileIsValidAnswersArray.includes(item));
    },

    mobileIsValidSuggestion(item) {
      return Boolean(this.mobileIsValidSuggestionArray.includes(item));
    },

    async checkselections() {
      if (
        Boolean(this.mobileSelectedAnswer) &&
        Boolean(this.mobileSelectedSuggestion)
      ) {
        const answer = {
          choiceA: this.mobileSelectedAnswer.id,
          choiceB: this.mobileSelectedSuggestion.id,
          type: 'PAIRED',
        };

        await this.$axios
          .post(this.questionPostLink, answer, this.axiosPostPutConfig)
          .then((res) => {
            this.questionAnswers.forEach((el) => {
              if (
                el.id === this.mobileSelectedAnswer.id ||
                el.id === this.mobileSelectedSuggestion.id
              ) {
                el.isCorrect = res.data.data.correct;
                el.isSelected = true;
                el.idCombination = this.mobileSelectedSuggestion.id;
              }
            });

            if (this.modalType === 'challenge') {
              this.mobileIsValidAnswersArray.push(this.mobileSelectedAnswer.id);
              this.mobileIsValidSuggestionArray.push(
                this.mobileSelectedSuggestion.id
              );
              this.correctCount += 1;
              this.mobileSelectedAnswer = null;
              this.mobileSelectedSuggestion = null;
            } else {
              if (res.data.data.correct) {
                this.mobileIsValidAnswersArray.push(
                  this.mobileSelectedAnswer.id
                );
                this.mobileIsValidSuggestionArray.push(
                  this.mobileSelectedSuggestion.id
                );
                this.correctCount += 1;
                this.mobileSelectedAnswer = null;
                this.mobileSelectedSuggestion = null;
              } else {
                setTimeout(() => {
                  this.mobileSelectedAnswer = null;
                  this.mobileSelectedSuggestion = null;
                  this.mobileActiveIndexAnswer = [];
                  this.mobileActiveIndexSuggestion = [];
                }, 700);
              }
            }
          });

        this.$forceUpdate();
      }
    },

    async postPairedQuestion(suggestionId, answerId) {
      const answer = {
        choiceA: answerId,
        choiceB: suggestionId,
        type: 'PAIRED',
      };

      await this.$axios
        .post(this.questionPostLink, answer, this.axiosPostPutConfig)
        .then((res) => {
          this.questionAnswers.forEach((el) => {
            if (el.id === answerId || el.id === suggestionId) {
              el.isCorrect = res.data.data.correct;
              el.isSelected = true;
              el.idCombination = suggestionId;
            }
          });
        });
    },

    async checkSelectionsForChallenge(secondSuggestionId) {
      if (this.areTwoItemsFromDifferentPanelsSelected()) {
        let targetSuggestionId = this.mobileActionState.selectedQuestionId;
        let sourceAnswerId = this.mobileActionState.selectedAnswerId;

        this.findAnsweredQuestionBySuggestionId(targetSuggestionId).answerId =
          sourceAnswerId;

        await this.postPairedQuestion(targetSuggestionId, sourceAnswerId);

        this.checkAndEnableReadyButton();

        this.$forceUpdate();
        this.clearMobileActionState();
      } else if (
        this.areTwoItemsFromQuestionsPanelSelected(secondSuggestionId)
      ) {
        let firstSuggestionId = this.mobileActionState.selectedQuestionId;

        let firstAnsweredQuestion =
          this.findAnsweredQuestionBySuggestionId(firstSuggestionId);
        let secondAnsweredQuestion =
          this.findAnsweredQuestionBySuggestionId(secondSuggestionId);

        if (
          this.hasBothQuestionsAnswersAssigned(
            firstSuggestionId,
            secondSuggestionId
          )
        ) {
          let tmp = secondAnsweredQuestion.answerId;
          secondAnsweredQuestion.answerId = firstAnsweredQuestion.answerId;
          firstAnsweredQuestion.answerId = tmp;

          await this.postPairedQuestion(
            firstSuggestionId,
            firstAnsweredQuestion.answerId
          );
          await this.postPairedQuestion(
            secondSuggestionId,
            secondAnsweredQuestion.answerId
          );
        } else if (Boolean(secondAnsweredQuestion.answerId)) {
          firstAnsweredQuestion.answerId = secondAnsweredQuestion.answerId;
          secondAnsweredQuestion.answerId = null;

          await this.postPairedQuestion(
            firstSuggestionId,
            firstAnsweredQuestion.answerId
          );
        } else {
          secondAnsweredQuestion.answerId = firstAnsweredQuestion.answerId;
          firstAnsweredQuestion.answerId = null;

          await this.postPairedQuestion(
            secondSuggestionId,
            secondAnsweredQuestion.answerId
          );
        }

        this.checkAndEnableReadyButton();

        this.$forceUpdate();
        this.clearMobileActionState();
      }
    },

    areTwoItemsFromDifferentPanelsSelected() {
      return (
        Boolean(this.mobileActionState.selectedQuestionId) &&
        Boolean(this.mobileActionState.selectedAnswerId)
      );
    },

    areTwoItemsFromQuestionsPanelSelected(secondQuestionId) {
      return (
        Boolean(this.mobileActionState.selectedQuestionId) &&
        Boolean(secondQuestionId)
      );
    },

    hasBothQuestionsAnswersAssigned(firstSuggestionId, secondSuggestionId) {
      return (
        Boolean(
          this.findAnsweredQuestionBySuggestionId(firstSuggestionId).answerId
        ) &&
        Boolean(
          this.findAnsweredQuestionBySuggestionId(secondSuggestionId).answerId
        )
      );
    },

    findAnsweredQuestionBySuggestionId(suggestionId) {
      return this.mobileAnsweredQuestions.filter(
        (el) => el.suggestionId === suggestionId
      )[0];
    },

    clearMobileActionState() {
      this.mobileActionState.selectedQuestionId = null;
      this.mobileActionState.selectedAnswerId = null;
    },

    selectAnswerMobile(item) {
      this.mobileSelectedAnswer = item;
      this.mobileActiveIndexAnswer = item.id;
      this.checkselections(item);
      this.$forceUpdate();
    },

    selectAnswerMobileForChallenge(item) {
      this.mobileActionState.selectedAnswerId = item.id;
      this.checkSelectionsForChallenge();

      this.$forceUpdate();
    },

    selectSuggestionMobile(item) {
      this.mobileSelectedSuggestion = item;
      this.mobileActiveIndexSuggestion = item.id;
      this.checkselections(item);
      this.$forceUpdate();
    },

    selectSuggestionMobileForChallenge(item) {
      if (Boolean(this.mobileActionState.selectedQuestionId)) {
        if (item !== this.mobileActionState.selectedQuestionId) {
          this.checkSelectionsForChallenge(item);
        }
      } else {
        this.mobileActionState.selectedQuestionId = item;
        this.checkSelectionsForChallenge();
      }

      this.$forceUpdate();
    },

    async handleDrop(data, item) {
      let dragElement = null;

      const answer = {
        choiceA: data,
        choiceB: item.id,
        type: 'PAIRED',
      };

      if (this.$refs[`drag-${data}`]) {
        dragElement = this.$refs[`drag-${data}`][0].$el;
      }

      await this.$axios
        .post(this.questionPostLink, answer, this.axiosPostPutConfig)
        .then((res) => {
          this.questionAnswers.forEach((el) => {
            if (el.id === item.id || el.id === data) {
              el.isCorrect = res.data.data.correct;
              el.isSelected = true;
              el.idCombination = item.id;
            }
          });

          if (res.data.data.correct) {
            dragElement.classList.add('tile-empty');

            this.answeredQuestions.filter(
              (ans) => ans.suggestionId === item.id
            )[0].answerList = [data];

            this.correctCount += 1;
          }

          if (this.modalType === 'challenge') {
            dragElement.classList.add('tile-empty');
            this.correctCount += 1;
          }
        });

      this.$forceUpdate();
    },

    async handleDropWithList(targetQuestionPanel, sourceAnswerPanel) {
      let dragElement = null;

      let fromList = sourceAnswerPanel.list;
      let toList = targetQuestionPanel.answerList;

      let isMoveFromPanel = fromList.length === 0;

      let wasToListEmpty = true;
      if (toList.length > 0) {
        wasToListEmpty = false;
      }

      if (isMoveFromPanel && !wasToListEmpty) {
        let dragElementToRestore = this.$refs[`drag-${toList[0]}`][0].$el;
        dragElementToRestore.classList.remove('tile-empty');
      }

      if (fromList) {
        if (!wasToListEmpty) {
          fromList.push(toList.pop());
        }
        toList.push(sourceAnswerPanel.item);
        fromList.splice(fromList.indexOf(sourceAnswerPanel.item), 1);
        toList.sort((a, b) => a > b);

        if (!wasToListEmpty && !isMoveFromPanel) {
          let switchedAnswerId = fromList[0];
          let switchedSuggestionId = this.answeredQuestions.filter((el) =>
            el.answerList.includes(switchedAnswerId)
          )[0].suggestionId;

          const switchedAnswer = {
            choiceA: switchedAnswerId,
            choiceB: switchedSuggestionId,
            type: 'PAIRED',
          };

          await this.$axios
            .post(
              this.questionPostLink,
              switchedAnswer,
              this.axiosPostPutConfig
            )
            .then((res) => {
              this.questionAnswers.forEach((el) => {
                if (
                  el.id === switchedSuggestionId ||
                  el.id === switchedAnswerId
                ) {
                  el.isCorrect = res.data.data.correct;
                  el.isSelected = true;
                  el.idCombination = switchedSuggestionId;
                }
              });
            });
        }
      }

      const answer = {
        choiceA: sourceAnswerPanel.item,
        choiceB: targetQuestionPanel.suggestionId,
        type: 'PAIRED',
      };

      this.answeredQuestions.filter(
        (ans) => ans.suggestionId === targetQuestionPanel.suggestionId
      ).answerList = [sourceAnswerPanel.item];

      if (isMoveFromPanel && this.$refs[`drag-${sourceAnswerPanel.item}`]) {
        dragElement = this.$refs[`drag-${sourceAnswerPanel.item}`][0].$el;
      }

      await this.$axios
        .post(this.questionPostLink, answer, this.axiosPostPutConfig)
        .then((res) => {
          this.questionAnswers.forEach((el) => {
            if (
              el.id === targetQuestionPanel.suggestionId ||
              el.id === sourceAnswerPanel.item
            ) {
              el.isCorrect = res.data.data.correct;
              el.isSelected = true;
              el.idCombination = targetQuestionPanel.suggestionId;
            }
          });

          if (isMoveFromPanel && res.data.data.correct) {
            dragElement.classList.add('tile-empty');
            this.correctCount += 1;
          }

          if (isMoveFromPanel && this.modalType === 'challenge') {
            dragElement.classList.add('tile-empty');
            this.correctCount += 1;
          }
        });

      this.$forceUpdate();
    },

    getSuggestions() {
      if (this.isReopenedQuestion) {
        let tmp = [];
        this.questionAnswers.forEach((el) => {
          tmp.push({
            name: el.b.name,
            id: el.b.id,
            correct: el.correct,
            given: el.given,
          });
        });

        this.gameSuggestions = tmp;
      } else {
        this.gameSuggestions = this.questionAnswers.filter(
          (e, i) => (i % 2) - 1
        );

        this.updateAnswersSuggestionId();
      }
    },

    getAnswers() {
      if (this.isReopenedQuestion) {
        let tmp = [];
        this.questionAnswers.forEach((el) => {
          tmp.push({
            name: el.a.name,
            id: el.a.id,
            correct: el.correct,
            given: el.given,
          });
        });

        this.gameAnswers = tmp;
      } else {
        this.gameAnswers = this.questionAnswers.filter((e, i) => i % 2);
      }
    },

    updateAnswersSuggestionId() {
      this.answeredQuestions = [];
      this.mobileAnsweredQuestions = [];

      this.gameSuggestions.forEach((el) => {
        this.answeredQuestions.push({
          suggestionId: el.id,
          answerList: [],
        });

        this.mobileAnsweredQuestions.push({
          suggestionId: el.id,
          answerId: null,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question {
  background-color: var(--v-primary-base);
  position: relative;

  &-counter {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-align: center;
  }

  &-title {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 4px;
  }

  &-text {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--v-secondary-base);
  }

  &-answer {
    margin-bottom: 0;
  }
}

.ak-puzzle {
  &-answer-wrapper {
    width: 100%;
    max-width: 343px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media (min-width: map-get($grid-breakpoints, md)) {
      display: flex;
      width: calc(52% - 4px);
    }
  }

  &-mobile-height {
    height: 83px;
  }

  &-desktop-height {
    height: 83px;

    @media (max-height: 900px) {
      height: 63px;
    }
  }

  &-tile {
    max-width: 171px;
    width: 100%;
    margin-bottom: 8px;
    background-color: var(--v-secondary-base);
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--v-text-base);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    span {
      width: 100%;
      overflow: scroll;
      height: 100%;
      display: flex;
      word-break: break-word;
      align-items: center;
      align-content: center;
      text-align: center;
      line-height: 1.2;
      justify-content: center;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    &.dropped-answer {
      background-color: var(--v-accent-base);
      margin-top: 8px;
    }

    &.suggestions {
      width: inherit;

      &:before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        right: -12px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        background-color: var(--v-secondary-base);
        border-radius: 50%;
      }

      .v-input__slot {
        height: 100%;
      }

      &.tile-empty {
        &:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 29px;
          left: -18px;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
          background-color: transparent;
          border: 1px dashed #fff;
          border-radius: 50%;
        }

        &:before {
          display: none;
        }
      }

      &.tile-correct {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          background-color: var(--v-success-base);
          top: 0;
          height: 100%;
          width: 14px;
          z-index: 3;
        }
        &:before {
          background-color: var(--v-success-base);
          border-right: 1px solid var(--v-primary-base);
          border-top: 1px solid var(--v-primary-base);
          border-bottom: 1px solid var(--v-primary-base);
        }

        &.second {
          &:after,
          &:before {
            display: none;
          }
        }
      }

      &.tile-incorrect {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          background-color: var(--v-error-base);
          top: 0;
          height: 100%;
          width: 14px;
          z-index: 3;
        }
        &:before {
          background-color: var(--v-error-base);
          border-right: 1px solid var(--v-primary-base);
          border-top: 1px solid var(--v-primary-base);
          border-bottom: 1px solid var(--v-primary-base);
        }

        &.second {
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }

    &.answer {
      width: calc(50% - 2px);
      margin: 0 0 7px;

      &:before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        background-color: var(--v-primary-base);
        clip-path: circle(48.6% at 0 50%);
      }
    }

    &.tile {
      &-correct {
        background-color: var(--v-success-base);
        color: #fff;
        pointer-events: none;
        &:after,
        &:before {
          display: none;
        }
      }

      &-incorrect {
        background-color: var(--v-error-base);
        color: #fff;
        &:after,
        &:before {
          display: none;
        }
      }

      &-selected {
        background-color: var(--v-accent-base);
        pointer-events: none;
        &:after,
        &:before {
          display: none;
        }
      }

      &-selected-but-movable {
        background-color: var(--v-accent-base);
        &:after,
        &:before {
          display: none;
        }
      }

      &-empty {
        background-color: transparent;
        border: 1px dashed #fff;
        position: relative;
        overflow: hidden;
        color: #fff;
      }

      &-disable {
        filter: opacity(0.5);
        pointer-events: none;
      }
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    max-width: 343px;
    margin: auto auto 15px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin: auto auto 25px;
    }
  }
}

.ak-puzzle-answer-wrapper {
  .tile-empty {
    pointer-events: none;
    font-size: 0;
    overflow: visible;
  }
}

.answer-number {
  position: absolute;
  top: 5px;
  left: 10px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 12px;
}

::v-deep .tile-selection {
  height: 100%;

  .v-input,
  .v-input__control,
  .v-select__selections {
    height: 100%;

    input {
      text-align: center;
      color: #fff;
    }
  }
  .v-select__slot {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
  }

  .v-label {
    color: #fff;
    left: auto !important;
    bottom: 0;
    margin: auto;
    top: 0;
  }

  .v-input__append-inner {
    display: none;
  }

  .v-select__selection {
    height: 100%;
    display: flex;
    width: 100%;
    text-align: center;
    margin: auto !important;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .ak-hint {
  font-family: 'Roboto' !important;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #f1f0ec !important;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.blocked-all {
  pointer-events: none;
}
</style>
