<template>
  <v-col
    cols="12"
    class="ak-levels pa-0 d-flex align-center"
    :class="[
      { 'ak-levels-notActive': !isGame && !fromChallengePage },
      { 'al-levels-done': gameStatus },
    ]"
    @click="openGame(gameId)"
  >
    <div class="ak-levels-wrapper d-flex justify-space-between align-center">
      <p class="mb-0 ak-levels-title">{{ levelTitle }}</p>
      <template v-if="gameStatus">
        <mdicon class="ak-icon-status" name="Check" size="22" />
      </template>
      <template v-else>
        <mdicon
          class="ak-icon"
          :class="{ 'white-icon': !isGame && !fromChallengePage }"
          :name="isLocked ? 'Lock' : 'LockOpenVariant'"
          size="22"
        />
      </template>
    </div>
    <template v-if="isGame">
      <QuestionsModal
        v-for="(item, index) in games"
        :key="index"
        :selectedQuestions="games[0].questions"
        :dialogIsOpen="dialogIsOpen"
        :postCompleteRunLink="item.postCompleteRunLink"
        @closeModal="closeModal"
        @updateList="updateList"
        modalType="casual"
      />
    </template>
    <template v-else>
      <template v-if="onlySummary">
        <ChallengeModal
          :dialogIsOpen="dialogIsOpen"
          :challengeLinkGet="challengeLinkGet"
          :challengeLinkPostRun="challengeLinkPostRun"
          :challengeLinkGetSummary="challengeLinkGetSummary"
          @closeModal="closeModal"
          @updateList="updateList"
          @repeatChallenge="repeatChallenge"
          :levelTitle="levelTitle"
          :unitTitle="unitTitle"
          :unitId="unitId"
          :gameId="gameId"
        />
      </template>

      <template v-else>
        <ChallengeModal
          v-for="(item, index) in games"
          :key="index"
          :selectedQuestions="games[0].questions"
          :dialogIsOpen="dialogIsOpen"
          :challengeLinkGet="challengeLinkGet"
          :challengeLinkPostRun="challengeLinkPostRun"
          :challengeLinkGetSummary="challengeLinkGetSummary"
          :postCompleteRunLink="item.postCompleteRunLink"
          @closeModal="closeModal"
          @updateList="updateList"
          @openSummary="openSummary"
          @repeatChallenge="repeatChallenge"
          :levelTitle="levelTitle"
          :unitTitle="unitTitle"
          :unitId="unitId"
          :gameIsStart="true"
          modalType="challenge"
          :timeAvailable="item.timeAvailable"
          :timeSpent="item.timeSpent"
          :isCompleted="gameStatus"
          :reopenChallenge="reopenChallenge"
          :gameId="gameId"
        />
      </template>
    </template>
  </v-col>
</template>

<script>
import QuestionsModal from '@/components/modules/games/QuestionsModal';
import ChallengeModal from '@/components/modules/games/ChallengeModal';

export default {
  name: 'LearnUnitLevelItem',
  components: { QuestionsModal, ChallengeModal },
  props: {
    levelId: {
      type: String,
      default: null,
    },
    unitId: {
      type: String,
      default: null,
    },
    levelTitle: {
      type: String,
      default: null,
    },
    gameId: {
      type: String,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: true,
    },
    gameStatus: {
      type: Boolean,
      default: false,
    },
    gameLinkPostRun: {
      type: String,
      default: null,
    },
    gameLinkGetRun: {
      type: String,
      default: null,
    },
    challengeLinkGet: {
      type: String,
      default: null,
    },
    challengeLinkPostRun: {
      type: String,
      default: null,
    },
    challengeLinkGetSummary: {
      type: String,
      default: null,
    },
    unitTitle: {
      type: String,
      default: 'Unit title',
    },
    isGame: {
      type: Boolean,
      default: true,
    },
    fromChallengePage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogIsOpen: false,
      reopenChallenge: false,
      runId: null,
      games: [],
      runPutLink: null,
      onlySummary: false,
    };
  },

  methods: {
    updateList() {
      this.$emit('updateList');
    },

    createRun(gameId, unitId, levelId) {
      let tmpLink = `/api/v1/user/learningunits/${unitId}/levels/${levelId}/${
        this.isGame ? 'games' : 'challenges'
      }/${gameId}/runs`;

      this.$axios.post(tmpLink, this.axiosPostPutConfig).then((res) => {
        this.runId = res.data.data.id;
        this.getGameInfo(gameId, unitId, levelId, res.data.data.id);
      });

      this.$forceUpdate();
    },

    getChallengeInfo() {
      this.$axios
        .get(`${this.challengeLinkGet}/?page=0&limit=100`, this.axiosGetConfig)
        .then((res) => {
          if (Boolean(res.data.data) && res.data.data.length > 0) {
            res.data.data.forEach((el) => {
              let postCompleteLink = null;
              if (el.links) {
                postCompleteLink = el.links.filter(
                  (item) => item.method == 'PUT' && item.rel == 'complete'
                );
              }

              this.games.push({
                id: el.data.id,
                completed: el.data.completed,
                questions: el.data.questions,
                timeAvailable: el.data.timeAvailable,
                timeSpent: el.data.timeSpent,
                postCompleteRunLink:
                  postCompleteLink !== null
                    ? postCompleteLink.length > 0
                      ? postCompleteLink[0].href
                      : null
                    : null,
              });
            });
          }
        });

      this.$forceUpdate();
    },

    getGameInfo(gameId, unitId, levelId, runId) {
      let tmpLink = `/api/v1/user/learningunits/${unitId}/levels/${levelId}/${
        this.isGame ? 'games' : 'challenges'
      }/${gameId}/runs/${runId}?page=0&limit=100`;

      this.$axios.get(tmpLink, this.axiosGetConfig).then((res) => {
        if (Boolean(res.data.data) && res.data.data.length > 0) {
          res.data.data.forEach((el) => {
            let postCompleteLink = null;

            if (el.links) {
              postCompleteLink = el.links.filter(
                (item) => item.method == 'PUT' && item.rel == 'complete'
              );
            }

            if (this.isGame) {
              this.games.push({
                id: el.data.id,
                completed: el.data.completed,
                questions: el.data.questions,
                postCompleteRunLink:
                  postCompleteLink !== null
                    ? postCompleteLink.length > 0
                      ? postCompleteLink[0].href
                      : null
                    : null,
              });
            } else {
              this.games.push({
                id: el.data.id,
                completed: el.data.completed,
                questions: el.data.questions,
                timeAvailable: el.data.timeAvailable,
                timeSpent: el.data.timeSpent,
                postCompleteRunLink:
                  postCompleteLink !== null
                    ? postCompleteLink.length > 0
                      ? postCompleteLink[0].href
                      : null
                    : null,
              });
            }
          });
        }
      });

      this.$forceUpdate();
    },

    openGame(val) {
      let gameId = val;
      let unitId = this.$route.params.unitId || this.unitId;
      let levelId = this.levelId;

      if (this.reopenChallenge) {
        this.createRun(gameId, unitId, levelId);
      } else {
        if (Boolean(this.challengeLinkGetSummary)) {
          this.onlySummary = true;
        } else if (Boolean(this.challengeLinkGet)) {
          this.getChallengeInfo();
        } else {
          this.createRun(gameId, unitId, levelId);
        }
      }

      if (val) {
        this.dialogIsOpen = true;
      } else {
        return false;
      }
    },

    openSummary() {
      this.onlySummary = true;
    },

    closeModal() {
      this.reopenChallenge = false;
      this.dialogIsOpen = false;
      this.games = [];
    },

    repeatChallenge(gameId) {
      this.reopenChallenge = true;
      this.onlySummary = false;
      this.dialogIsOpen = false;
      this.games = [];
      this.openGame(gameId);
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-levels {
  background-color: var(--v-secondary-base);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
  max-width: 500px;
  margin: auto;
  cursor: pointer;
  height: 50px;

  &-wrapper {
    padding: 15px 17px;
    width: 100%;
  }
  &-notActive {
    background-color: var(--v-orange-base);
  }

  &-title {
    font-family: 'SFProText';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--v-text-base);
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.ak-levels-notActive .ak-levels-title {
  color: #fff;
}

::v-deep .ak-levels-done {
  cursor: text;
}

.ak-icon {
  &-status {
    background-color: var(--v-success-base);
    color: #fff;
    height: 26px;
    display: block;
    height: 26px;
    width: 26px;
    border-radius: 50%;
  }

  &.white-icon {
    color: #fff;
  }
}

.ak-challengelist {
  background-color: var(--v-brightBlue-base);

  .ak-levels-title {
    color: #fff;
  }
}
</style>
