<template>
  <v-row class="text-h5 ma-0 ak-modal-title">
    <template v-if="!isReopenedQuestion">
      <CountDownTimer :timing="timeAvailable - timeSpent" @timeOut="timeOut" />

      <template v-if="isMemory">
        <CountDownTimer
          :timingMemory="timingMemory - timingMemorySpent"
          @timeOut="timeOut"
          @memoryTimeOut="memoryTimeOut"
          :isMemory="isMemory"
          :isCurrentQuestion="isCurrentQuestion"
          :isStartMemoryTimer="isStartMemoryTimer"
        />
      </template>
    </template>

    <v-col cols="12" class="ak-question-title pa-0">
      {{ unitTitle }}
      <br />
      <strong>{{ levelTitle }}</strong>
    </v-col>
    <v-col
      cols="12"
      class="ak-question-text pa-0"
      :class="{ 'mt-10': isMemory && !isReopenedQuestion }"
    >
      {{ title }}
    </v-col>
    <template
      v-if="
        isReopenedQuestion && /^\w/.test(description) && description !== null
      "
    >
      <v-col cols="12" class="ak-question-tooltip pa-0">
        <p class="mb-0">💡 {{ description }}</p>
      </v-col>
    </template>
    <v-col cols="auto" @click="closeModal" class="pa-0">
      <mdicon class="ak-icon-close" name="CloseCircle" size="22" />
    </v-col>
  </v-row>
</template>

<script>
import CountDownTimer from '@/components/modules/games/common/CountDownTimer';

export default {
  name: 'ModalTitleChallenge',
  components: { CountDownTimer },
  props: {
    isCurrentQuestion: {
      type: Boolean,
      default: false,
    },
    timeAvailable: {
      type: Number,
      default: null,
    },
    timeSpent: {
      type: Number,
      default: null,
    },
    timingMemory: {
      type: Number,
      default: null,
    },
    timingMemorySpent: {
      type: Number,
      default: null,
    },
    unitTitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    levelTitle: {
      type: String,
      default: null,
    },
    isMemory: {
      type: Boolean,
      default: false,
    },
    isStartMemoryTimer: {
      type: Boolean,
      default: false,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timeIsOut: false,
      memoryTimeIsOut: false,
    };
  },

  watch: {
    timeIsOut(val) {
      if (val) {
        this.$emit('timeOut');
      }
    },

    memoryTimeIsOut(val) {
      if (val) {
        this.$emit('memoryTimeOut');
      }
    },
  },

  methods: {
    timeOut(val) {
      this.timeIsOut = val;
    },

    memoryTimeOut(val) {
      this.memoryTimeIsOut = val;
    },

    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-modal-title {
  background-color: var(--v-primary-base);
  padding: 0;
  z-index: 2;
  position: relative;
}
.ak-question {
  &-title {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 4px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 34px;
  }

  &-text {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--v-secondary-base);
  }

  &-tooltip {
    margin-top: 18px;
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #f1f0ec;
  }
}

.ak-icon-close {
  position: absolute;
  top: -15px;
  right: 0;
  color: #fff;
  cursor: pointer;
}
</style>
