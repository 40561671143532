<template>
  <v-dialog
    v-model="dialogIsOpen"
    @keydown.esc="closeModal"
    @click:outside="closeModal"
    width="80vw"
    max-width="700"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    class="ak-question-wrapper"
  >
    <v-card
      elevation="0"
      class="ak-question pa-0"
      v-for="(item, index) in selectedQuestions"
      :key="index"
      :class="{
        'current-question': index === passedQuestions - 1,
      }"
    >
      <v-card-text class="px-0">
        <v-container class="pt-4 pt-md-6 px-4">
          <ModalTitle
            @closeModal="closeModal"
            :title="item.name"
            :text="item.description"
          />
        </v-container>
        <v-container>
          <template v-if="item.type === 'MULTI_CHOICE'">
            <TextQuestion
              :questionAnswers="item.answerOptions"
              :questionAnswersCountRequired="item.answersRequired"
              :questionPostLink="item.links | getPostLink"
              :passedQuestions="passedQuestions"
              :isReopenedQuestion="isReopenedQuestion"
              @closeModal="closeModal"
              @clearGame="clearGame"
              @validGoNextButton="validGoNextButton"
            />
          </template>

          <template v-if="item.type === 'IMAGE'">
            <PictureQuestion
              :questionPicture="item.imageSrc"
              :questionAnswers="item.answerOptions"
              :questionPostLink="item.links | getPostLink"
              :passedQuestions="passedQuestions"
              :isReopenedQuestion="isReopenedQuestion"
              @closeModal="closeModal"
              @clearGame="clearGame"
              @validGoNextButton="validGoNextButton"
            />
          </template>

          <template v-if="item.type === 'MEMORY'">
            <MemoryQuestion
              :questionAnswers="item.answerOptions"
              :questionPostLink="item.links | getPostLink"
              :passedQuestions="passedQuestions"
              :isReopenedQuestion="isReopenedQuestion"
              @closeModal="closeModal"
              @clearGame="clearGame"
              @validGoNextButton="validGoNextButton"
            />
          </template>

          <template v-if="item.type === 'PUZZLE'">
            <PuzzleQuestion
              :questionAnswers="item.answerOptions"
              :questionPostLink="item.links | getPostLink"
              :passedQuestions="passedQuestions"
              :isReopenedQuestion="isReopenedQuestion"
              @closeModal="closeModal"
              @clearGame="clearGame"
              @validGoNextButton="validGoNextButton"
            />
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5 px-4">
        <ModalActions
          :questionsCount="selectedQuestions.length"
          :passedQuestions="passedQuestions"
          :isReopenedQuestion="isReopenedQuestion"
          :disabledGoNext="!allDone"
          @goBack="goBack"
          @goNext="goNext"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalTitle from '@/components/modules/games/common/ModalTitle';
import TextQuestion from '@/components/modules/games/TextQuestion/TextQuestion';
import MemoryQuestion from '@/components/modules/games/MemoryQuestion/MemoryQuestion';
import PictureQuestion from '@/components/modules/games/PictureQuestion/PictureQuestion';
import PuzzleQuestion from '@/components/modules/games/PuzzleQuestion/PuzzleQuestion';
import ModalActions from '@/components/modules/games/common/ModalActions';

export default {
  name: 'QuestionsModal',
  components: {
    ModalTitle,
    TextQuestion,
    MemoryQuestion,
    PictureQuestion,
    PuzzleQuestion,
    ModalActions,
  },

  props: {
    dialogIsOpen: {
      type: Boolean,
      default: false,
    },
    selectedQuestions: {
      type: Array,
      default: () => [],
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
    postCompleteRunLink: {
      type: String,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      passedQuestions: 1,
      allDone: false,
    };
  },

  filters: {
    getPostLink(val) {
      let tmp = val.filter((el) => el.method == 'POST' && el.rel == 'answer');
      return tmp[0].href;
    },
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },

    clearGame() {
      this.passedQuestions = 1;
    },

    goBack() {
      if (this.isReopenedQuestion) {
        this.$emit('closeReopen');
      } else {
        if (this.passedQuestions === 1) {
          this.closeModal();
        } else {
          this.passedQuestions -= 1;
        }
      }
    },

    validGoNextButton(val) {
      this.allDone = val;
    },

    async finishrun() {
      await this.$axios
        .put(this.postCompleteRunLink, this.axiosPostPutConfig)
        .then((res) => {
          console.log('AXIOS SUCCESS finish game run: ', res.statusText);
        })
        .catch((err) => {
          console.log('AXIOS ERROR finish game run: ', err);
        });
    },

    goNext() {
      if (this.passedQuestions === this.selectedQuestions.length) {
        this.finishrun();
        this.$emit('updateList');
        this.closeModal();
        return false;
      } else {
        this.passedQuestions += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ak-question {
  background-color: var(--v-primary-base);

  &-wrapper {
    height: calc(100vh - 75px);
    background-color: var(--v-primary-base);
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      position: relative;
      height: auto;
    }
  }
}

::v-deep .v-card__text {
  padding-bottom: 82px !important;
  /* padding-top: 50px !important; */
  min-height: 170px;
}

::v-deep .v-card__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--v-primary-base);
  z-index: 1;
  @media (min-width: map-get($grid-breakpoints, md)) {
    position: absolute;
  }
}

.v-card {
  display: none;

  &.current-question {
    display: block;
    position: relative;
  }
}

::v-deep .v-dialog--fullscreen {
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
</style>
