<template>
  <v-row class="text-h5 ma-0 ak-modal-title">
    <v-col cols="11" class="ak-question-title pa-0">
      {{ title }}
    </v-col>
    <v-col cols="11" class="ak-question-text pa-0">
      {{ text }}
    </v-col>
    <template v-if="isReopenedQuestion">
      <v-col cols="12" class="ak-question-tooltip pa-0">
        <p class="mb-0">
          💡 Hier steht eine Eselsbrücke zu diesem Thema. Hier steht eine
          Eselsbrücke zu diesem Thema.
        </p>
      </v-col>
    </template>

    <v-col cols="auto" @click="closeModal" class="pa-0">
      <mdicon class="ak-icon-close" name="CloseCircle" size="22" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ModalTitle',
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-modal-title {
  position: relative;
  background-color: var(--v-primary-base);
  padding: 0;
  z-index: 2;
}

.ak-question {
  &-title {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 4px;
  }

  &-text {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--v-secondary-base);
  }

  &-tooltip {
    margin-top: 18px;
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #f1f0ec;
  }
}

.ak-icon-close {
  position: absolute;
  top: -15px;
  right: 0;
  color: #fff;
  cursor: pointer;
}
</style>
