<template>
  <v-card color="var(--v-primary-base)">
    <v-card-title class="px-4">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="ak-summary-uppertitle">Auswertung letzter Versuch</p>
          <template v-if="Boolean(unitTitle)">
            <p class="ak-summary-title">{{ unitTitle }}</p>
          </template>
          <template v-if="Boolean(title)">
            <h3 class="ak-summary-title font-weight-bold mb-3">
              {{ title }}
            </h3>
          </template>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="ak-challenge-page px-2">
      <v-row class="ak-challenge-points flex-column px-3">
        <v-col
          cols="12"
          class="d-flex justify-space-between align-center ak-correct-answer px-0"
        >
          <v-col cols="5" md="auto" class="pa-0">
            <v-rating
              color="yellow"
              background-color="grey"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              length="3"
              readonly
              size="30"
              v-model="summaryInfo.stars"
            ></v-rating>
          </v-col>
          <v-col cols="5" class="pa-0">
            <p class="mb-0 font-roboto font16-400 text-start">
              Korrekte Antworten ({{ summaryInfo.percentage | rounded }}%)
            </p>
          </v-col>

          <v-col cols="2" sm="1" class="pa-0">
            <p class="mb-0 font16-600 text-end">
              {{ summaryInfo.gamePoints }}
            </p>
          </v-col>
        </v-col>
        <v-divider color="#6E6E6E" width="100%" />
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between align-center ak-middle-points px-0"
        >
          <v-col
            cols="4"
            md="3"
            class="pa-0 d-flex flex-column font-roboto ak-time-points"
          >
            <p class="mb-0">Benötigte Zeit</p>
            <p class="mb-0">{{ getTimeSpent }}</p>
            <p class="mb-0">max. Zeit {{ getTimeGeneral }}</p>
          </v-col>
          <v-col cols="8" md="9" class="pa-0 d-flex flex-row justify-end">
            <p class="mb-0">Zusatzpunkte</p>
            <v-col cols="4" md="2" class="pa-0">
              <p class="mb-0 font16-600 text-end">
                {{ summaryInfo.timePoints }}
              </p>
            </v-col>
          </v-col>
        </v-col>
        <v-divider color="#6E6E6E" width="100%" />
        <v-col
          cols="8"
          md="9"
          class="d-flex justify-end align-end ak-total-points px-0"
        >
          <p class="mb-0 ak-colorText-grey">Punkte</p>
          <v-col cols="4" md="2" class="pa-0">
            <p class="mb-0 text-end">{{ summaryInfo.totalPoints }}</p>
          </v-col>
        </v-col>
      </v-row>

      <v-row class="flex-column">
        <v-col cols="12" class="pa-0">
          <p class="white--text font-roboto font14-400">
            Klicke auf die Übungen um Details zu sehen
          </p>
        </v-col>
        <v-col
          v-for="(item, index) in summaryInfo.gameSummaries"
          :key="index"
          class="ak-question"
          @click="openGame(item.id)"
        >
          <p class="mb-0 font-roboto font16-600 ak-question-text">
            {{ item.name }}
          </p>
          <p
            class="mb-0 ak-question-status"
            :class="[
              item.totalQuestions - item.correctAnswers === 0
                ? 'ak-icon-success'
                : 'ak-icon-incorrect',
            ]"
          >
            <template v-if="item.totalQuestions - item.correctAnswers !== 0">
              {{ item.totalQuestions - item.correctAnswers }}
            </template>
            <template v-else>
              <mdicon name="Check" />
            </template>
          </p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pb-5 px-4">
      <v-row no-gutters class="full-width">
        <v-col
          cols="12"
          class="d-flex justify-space-between flex-column flex-md-row"
        >
          <AKButton
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
            title="Challenge wiederholen"
            class="ak-challenge-restart-btn"
            @click="restartChallenge()"
          />
          <AKButton
            height="50"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
            title="Schliessen"
            :color="$vuetify.theme.themes.light.brightBlue"
            @click="closeChallenge()"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'ChallengesSummary',
  components: { AKButton },
  props: {
    title: {
      type: String,
      default: null,
    },
    unitTitle: {
      type: String,
      default: 'Unit title',
    },
    summaryInfo: {
      type: Object,
      default: () => {},
    },
    summaryInfoLinks: {
      type: Array,
      default: () => [],
    },
  },

  filters: {
    rounded(val) {
      return Math.round((val + Number.EPSILON) * 100) / 100;
    },
  },

  methods: {
    restartChallenge() {
      this.$emit('repeatChallenge');
    },

    closeChallenge() {
      this.$emit('updateList');
      this.$emit('closeChallenge');
    },

    openGame(id) {
      this.$emit('reOpenQuestion', id);
    },
  },

  computed: {
    getTimeGeneral() {
      let d = Number(
        this.summaryInfo.secondsLeft + this.summaryInfo.secondsSpent
      );
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? 'h:' : 'h:') : '';
      let mDisplay = m > 0 ? m + 'm:' : '';
      let sDisplay = s > 0 ? (s > 9 ? s : '0' + s + 's') : '00s';
      return hDisplay + mDisplay + sDisplay;
    },

    getTimeSpent() {
      let d = Number(this.summaryInfo.secondsSpent);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? 'h:' : 'h:') : '';
      let mDisplay = m > 0 ? m + 'm:' : '';
      let sDisplay = s > 0 ? (s > 9 ? s + 's' : '' + s + 's') : '00s';
      return hDisplay + mDisplay + sDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  display: block !important;

  &__title {
    color: #fff;
  }

  &__text {
    border-radius: 10px !important;
    margin: 0 20px;
    width: auto;
    background-color: #eee;
    padding: auto !important;
  }

  &__actions {
    justify-content: center;
    flex-direction: column;

    .v-btn {
      margin: auto;
    }

    .v-btn:not(:last-child) {
      margin: 0 auto 19px !important;
      @media (min-width: map-get($grid-breakpoints, md)) {
        margin: auto !important;
      }
    }
  }
}

.ak-total-points {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.ak-middle-points {
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.ak-time-points {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  p:last-child {
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
}

.ak-question {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: var(--v-secondary-base) !important;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 11px;
  align-items: center;

  &-text {
    color: #4b4b4b;
  }
}

.ak-icon {
  &-success,
  &-incorrect {
    color: #fff;
    border-radius: 50%;
    height: 26px;
    display: flex;
    width: 26px;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  &-success {
    background-color: var(--v-success-base);
  }

  &-incorrect {
    background-color: var(--v-error-base);
  }
}

.ak-challenge-page {
  background-color: transparent;
}

.ak-challenge-points {
  background-color: #eee;
  border-radius: 10px;
  margin-bottom: 30px;
  align-items: flex-end;
}

.ak-summary-title,
.ak-summary-uppertitle {
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #f1f0ec;
  margin-bottom: 0;
}

.ak-summary-uppertitle {
  margin-bottom: 23px;
}

.ak-challenge-restart-btn {
  color: var(--v-fontGrey-base);
}
</style>
