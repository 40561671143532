var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:(("padding-top: " + _vm.paddingTop + "px")),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"ak-question-image mx-auto",attrs:{"position":"center","max-height":_vm.$vuetify.breakpoint.lgAndDown ? 275 : 350,"max-width":_vm.$vuetify.breakpoint.lgAndDown ? 319 : 450,"contain":"","src":_vm.questionPicture,"alt":""}})],1),_c('v-col',{staticClass:"d-flex justify-space-between flex-wrap",attrs:{"cols":"12"}},_vm._l((_vm.questionAnswers),function(item,index){return _c('QuestionItem',{key:index,class:[
        { 'blocked-all': _vm.timeIsOut },
        !_vm.isReopenedQuestion && _vm.modalType === 'challenge' && _vm.isSelected(item)
          ? 'selected-answer'
          : _vm.isReopenedQuestion
          ? _vm.isCorrect(item)
            ? 'correct-answer'
            : _vm.isSelected(item)
            ? 'incorrect-answer'
            : ''
          : _vm.isSelected(item)
          ? _vm.isCorrect(item)
            ? 'correct-answer'
            : 'incorrect-answer'
          : '' ],attrs:{"text":item.name},on:{"selectAnswer":function($event){return _vm.selectAnswer(item)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }